import React from "react";
import "./ContactUs.scss";
import $ from "jquery";
import {
  InputTheme4,
  TextAreaTheme4,
} from "../../res/styler/InputThemes/InputThemes";
import {
  ButtonTheme1,
  ButtonTheme2,
} from "../../res/styler/ButtonThemes/ButtonThemes";
import MyContext from "../MyContext";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { SiGmail, SiWhatsapp } from "react-icons/si";
import title_image from "../../res/images/contact_us/title2.jpg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import x_logo from "../../res/images/X_logo_black.png";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      phone_number_code: "IN",
      message: "",
      captcha_token: "",
      checkbox: 0,
    };

    this.form_change = this.form_change.bind(this);
    this.form_submit = this.form_submit.bind(this);
    this.mobile_no_change = this.mobile_no_change.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.on_checkbox_change = this.on_checkbox_change.bind(this);
    this.contact_us_page_ref = React.createRef();
    this.contact_form_block_ref = React.createRef();
    this.captchaRef = React.createRef();
  }

  componentDidMount() {
    $(".maximize_button").on("click", function () {
      $(".answer").css("height", 0);
      $(".minimize_button").css("display", "none");
      $(".maximize_button").css("display", "block");
      $(this).parents(".question").siblings(".answer").heightAuto();
      $(this).css("display", "none");
      $(this).siblings(".minimize_button").css("display", "block");
    });

    $(".minimize_button").on("click", function () {
      $(this).parents(".question").siblings(".answer").css("height", "0px");
      $(this).css("display", "none");
      $(this).siblings(".maximize_button").css("display", "block");
    });
  }

  form_change(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  mobile_no_change(value) {
    this.setState({
      phone_number: value,
    });
  }

  onCountryChange(country) {
    const country_code = country ?? this.state.phone_number_code;
    this.setState({
      phone_number_code: country_code,
    });
  }

  on_checkbox_change(e) {
    var checkbox = this.state.checkbox;
    console.log(checkbox);
    this.setState({
      checkbox: checkbox ? 0 : 1,
    });
  }

  form_submit(e) {
    e.preventDefault();
    const token = this.captchaRef.current.getValue();
    this.captchaRef.current.reset();
    // console.log(this.state);
    var obj_this = this;
    var contact_details = this.state;
    contact_details.captcha_token = token;
    if (contact_details.captcha_token.length == 0) {
      $.alert("Please verify you are a human!");
      return;
    }
    this.context.loading(1);
    delete contact_details.checkbox;
    contact_details = JSON.stringify(contact_details);
    // console.log(contact_details);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/saveContactUsForm",
      data: {
        contact_details: contact_details,
      },
      type: "POST",
    })
      .done(function (return_data) {
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          alert("Thank you for getting in touch!");
          obj_this.setState({
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            phone_number_code: "IN",
            message: "",
            captcha_token: "",
            checkbox: 0,
          });
        } else {
          alert("Network error!!");
        }
      })
      .fail(function (return_data) {
        alert("Network error!!");
      });
  }

  render() {
    return (
      <div
        className="contact_us_page_container page_container"
        ref={this.contact_us_page_ref}
      >
        <div className="title_block">
          <div
            className="image_container"
            style={{ backgroundImage: "url('" + title_image + "')" }}
          ></div>
          <div className="contact_us_main_content">
            <div className="main_title">
              CONTACT <span>US.</span>
            </div>
            <div className="main_content">
              Amrityum strives to create a virtual platform for people to come
              together, get connected and remember and celebrate their deceased
              loved ones by publishing Death & Family Notices, Create a
              Memorial, as well as preserve our own stories and make them
              enduring by creating Life Events & Family Tree. Immortalise your
              tamed companions with Pet Tributes.
            </div>
          </div>
        </div>
        <div className="general_questions_block">
          <div className="general_questions_title">No question is too small!</div>
          <div className="general_questions_grid">
            <div>
              <div className="single_question_container">
                <div className="question">
                  Who can create a free Amrityum account?
                  <div className="maximimize_and_minimize_button_container">
                    <div className="maximize_button">
                      <AiOutlinePlus />
                    </div>
                    <div className="minimize_button">
                      {" "}
                      <AiOutlineMinus />
                    </div>
                  </div>
                </div>
                <div className="answer">
                  Anyone can create an Amrityum account. The service is for
                  everyone to use. There are three ways to create an account:
                  email, Google and Facebook. In all cases, Amrityum neither
                  displays your email nor shares it with anyone. If you use
                  social media to sign in, we only receive your name, email id
                  and profile photo, if you have any. You require an account to
                  contribute through comments and compliments to an already
                  published Amrityum service. Again, an account is needed to
                  publish any content for the services we offer. However, no
                  account is required to view the site.
                </div>
              </div>
              <div className="single_question_container">
                <div className="question">
                  How do I access Account & Settings Menu?
                  <div className="maximimize_and_minimize_button_container">
                    <div className="maximize_button">
                      <AiOutlinePlus />
                    </div>
                    <div className="minimize_button">
                      {" "}
                      <AiOutlineMinus />
                    </div>
                  </div>
                </div>
                <div className="answer">
                  After signing in, click on your photo or initials in the upper
                  right corner for the Account & Settings menu. From here, you
                  can access the features like: ‘My Notices’, where you find all
                  services, you have subscribed to or contributed to, along with
                  the 'pending approval’ section. ‘Edit Profile’ to change your
                  profile photo. And ‘Sign Out’ if you need to sign out from
                  your profile.
                </div>
              </div>
              <div className="single_question_container">
                <div className="question">
                  Can I create an account if I don&#39;t have all the
                  information yet?
                  <div className="maximimize_and_minimize_button_container">
                    <div className="maximize_button">
                      <AiOutlinePlus />
                    </div>
                    <div className="minimize_button">
                      {" "}
                      <AiOutlineMinus />
                    </div>
                  </div>
                </div>
                <div className="answer">
                  Yes, only a few pieces of information are essential to begin.
                  You may start with all the basic information that you have
                  already gathered. Additional information can be added anytime.
                  You may also collaborate with family and friends to complete
                  the required profile and share it with everyone.
                </div>
              </div>
              <div className="single_question_container">
                <div className="question">
                  How to create an Amrityum space to enter a lifelog?
                  <div className="maximimize_and_minimize_button_container">
                    <div className="maximize_button">
                      <AiOutlinePlus />
                    </div>
                    <div className="minimize_button">
                      {" "}
                      <AiOutlineMinus />
                    </div>
                  </div>
                </div>
                <div className="answer">
                  Click{" "}
                  <span
                    onClick={() => this.props.history.push("/Register")}
                    className="page_link"
                  >
                    {" "}
                    here{" "}
                  </span>
                  to register if you don’t have an Amrityum account already.
                  After the registration, click on the profile icon on the top
                  right-hand corner and click on ‘Dashboard’. Choose the service
                  you require, complete all the required spaces and enter
                  content as prompted. You only need a few minutes to
                  personalize your Amrityum space and share it with others. You
                  have the option to preview the post before you submit it for
                  our approval.
                </div>
              </div>
              <div className="single_question_container">
                <div className="question">
                  How to fix incorrect content (Names, Dates, etc.) or take
                  ownership of a Death Notice created by our partners on your
                  behalf?
                  <div className="maximimize_and_minimize_button_container">
                    <div className="maximize_button">
                      <AiOutlinePlus />
                    </div>
                    <div className="minimize_button">
                      {" "}
                      <AiOutlineMinus />
                    </div>
                  </div>
                </div>
                <div className="answer">
                  To make any corrections, you must sign in to join Amrityum.
                  Again, you must sign in to take control of a Death Notices
                  created by our partners. Once the transfer is complete, you
                  will be the administrator.
                </div>
              </div>
            </div>
            <div>
              <div className="single_question_container">
                <div className="question">
                  Can I keep the Amrityum page I create private?
                  <div className="maximimize_and_minimize_button_container">
                    <div className="maximize_button">
                      <AiOutlinePlus />
                    </div>
                    <div className="minimize_button">
                      {" "}
                      <AiOutlineMinus />
                    </div>
                  </div>
                </div>
                <div className="answer">
                  No. One of the objectives of Amrityum is to celebrate the
                  lives of those gone before us and of our own. If we are to
                  honour these lives and preserve them for family and friends to
                  come, they need to be in the public sphere.
                </div>
              </div>

              <div className="single_question_container">
                <div className="question">
                  How do you deal with offensive Content?
                  <div className="maximimize_and_minimize_button_container">
                    <div className="maximize_button">
                      <AiOutlinePlus />
                    </div>
                    <div className="minimize_button">
                      {" "}
                      <AiOutlineMinus />
                    </div>
                  </div>
                </div>
                <div className="answer">
                  Despite all the efforts taken, we understand that sometimes
                  people post contents or comments which are inappropriate or
                  offensive. We have provided a ‘Report’ button, and you may use
                  it to bring them to our attention. We hope to review them
                  promptly and will delete contents that violate our terms and
                  conditions or community guidelines.
                </div>
              </div>
              <div className="single_question_container">
                <div className="question">
                  How do I delete an Amrityum page?
                  <div className="maximimize_and_minimize_button_container">
                    <div className="maximize_button">
                      <AiOutlinePlus />
                    </div>
                    <div className="minimize_button">
                      {" "}
                      <AiOutlineMinus />
                    </div>
                  </div>
                </div>
                <div className="answer">
                  If you are the administrator of any Amrityum Services, you can
                  delete it from your ‘Dashboard’. However, you cannot delete
                  any page if you are not the administrator. Not the
                  administrator of the main page?{" "}
                  <span
                    className="page_link"
                    onClick={() =>
                      this.contact_form_block_ref.current.scrollIntoView({
                        block: "start",
                        inline: "nearest",
                      })
                    }
                  >
                    Contact us
                  </span>{" "}
                  to make your request. We review each case individually and
                  will work to resolve your issue based on our Terms &
                  Conditions and Community Guidelines.
                </div>
              </div>
              <div className="single_question_container">
                <div className="question">
                  How to contact Amrityum Support?
                  <div className="maximimize_and_minimize_button_container">
                    <div className="maximize_button">
                      <AiOutlinePlus />
                    </div>
                    <div className="minimize_button">
                      {" "}
                      <AiOutlineMinus />
                    </div>
                  </div>
                </div>
                <div className="answer">
                  Our support team is available by email at:
                  <a href="mailto:info@amrityum.com" className="page_link">
                    {" "}
                    info@amrityum.com
                  </a>{" "}
                  or by phone at:{" "}
                  <a href="tel:7558900111" className="page_link">
                    {" "}
                    +91 755 89 00 111.{" "}
                  </a>
                  Alternatively, you may look at our{" "}
                  <span
                    className="page_link"
                    onClick={() => this.props.history.push("/FAQs")}
                  >
                    {" "}
                    FAQs
                  </span>{" "}
                  Page. We always strive to get back to you as soon as possible.
                </div>
              </div>
              <div className="single_question_container">
                <div className="question">
                  Can there be more than one page created to honour the same
                  person?
                  <div className="maximimize_and_minimize_button_container">
                    <div className="maximize_button">
                      <AiOutlinePlus />
                    </div>
                    <div className="minimize_button">
                      {" "}
                      <AiOutlineMinus />
                    </div>
                  </div>
                </div>
                <div className="answer">
                  For some Amrityum services, more than one page can be created
                  for the same person, as we don’t recommend any page to be the
                  ‘official page’ to honour someone. However, we usually apply
                  several checkpoints and warn you of an existing page before
                  another one. Sometimes we understand there can be legitimate
                  reasons for two siblings to create separate pages for their
                  parent/s. Each of these pages will have the support of all
                  Amrityum features.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="more_details_block" ref={this.contact_form_block_ref}>
          <div className="contact_us_form_grid">
            <div className="contact_us_details">
              <div className="title">Get in touch with us!</div>
              <div className="about">
                <p>
                  We are here to help and answer any questions you might have!
                  We strive to provide the best service possible with every
                  contact! We look forward to hearing from you. Thank you for
                  using Amrityum. Please complete the form below, so we can
                  provide quick and efficient service.
                </p>
              </div>
              <div className="icon_details_grid">
                <div className="icon_container">
                  <FaMapMarkerAlt className="icon" />
                </div>
                <div className="details_container">
                  <div className="heading">Office</div>
                  <div className="content">
                    Amrityum, Nagapuzha PO, Kalloorkad, Ernakulam, Kerala, India
                    - 686 668
                  </div>
                </div>
                <div className="icon_container">
                  <FaPhoneAlt className="icon" />
                </div>
                <div className="details_container">
                  <div className="heading">Call us</div>
                  <div className="content">
                    <a href="tel:+91 7558 900 111">+91 7558 900 111</a>
                  </div>
                </div>
                <div className="icon_container">
                  <SiWhatsapp className="icon" />
                </div>
                <div className="details_container">
                  <div className="heading">Whatsapp</div>
                  <div className="content">
                    <a
                      href={"https://api.whatsapp.com/send?phone=7558900111"}
                      target={"_blank"}
                    >
                      +91 7558 900 111
                    </a>
                  </div>
                </div>
                <div className="icon_container">
                  <SiGmail className="icon" />
                </div>
                <div className="details_container">
                  <div className="heading">Mail us</div>
                  <div className="content">
                    <a href="mailto:info@amrityum.com">info@amrityum.com</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="form_container">
              <form className="contact_us_form" onSubmit={this.form_submit}>
                <input
                  type="text"
                  name="first_name"
                  value={this.state.first_name}
                  onChange={this.form_change}
                  required={true}
                  placeholder="First Name *"
                />
                <input
                  type="text"
                  name="last_name"
                  value={this.state.last_name}
                  onChange={this.form_change}
                  placeholder="Last Name"
                />
                <input
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.form_change}
                  required={true}
                  placeholder="Email *"
                />
                {/* <input
                type = "tel"
                name = "phone_number"
                value = {this.state.phone_number}
                pattern ="[6789][0-9]{9}"
                onChange = {this.form_change}
                placeholder="Phone Number *"
                maxLength={10}
              /> */}
                <PhoneInput
                  style={{ color: this.state.phone_number ? "#111" : "" }}
                  placeholder="Phone number *"
                  international
                  value={this.state.phone_number}
                  onChange={this.mobile_no_change}
                  defaultCountry={this.state.phone_number_code}
                  required={true}
                  onCountryChange={(country) => this.onCountryChange(country)}
                />
                <textarea
                  name="message"
                  value={this.state.message}
                  onChange={this.form_change}
                  required={true}
                  placeholder="Message *"
                />
                <div className="declaration">
                  <input
                    type="checkbox"
                    required value={this.state.checkbox}
                    //value={this.state.checkbox}
                    onChange={this.on_checkbox_change}
                    checked={this.state.checkbox}
                  />
                  &nbsp;By submitting this form, I acknowledge the receipt of
                  Amrityum{" "}
                  {/* <span
                    className="page_link"
                    onClick={() => this.props.history.push("/PrivacyPolicy")}
                  >
                    Privacy Policy.
                  </span> */}
                  <Link
                    to="/PrivacyPolicy"
                    className="page_link"
                    target={"_blank"}
                  >
                    Privacy Policy.
                  </Link>
                </div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={this.captchaRef}
                />
                <ButtonTheme2 className="submit_button" label="SUBMIT" />
              </form>
            </div>
          </div>
        </div>
        <div className="follow_us_block">
          <div className="title" style={{ textAlign: "center" }}>
            Connect with us
          </div>
          <p className="follow_us_label">
            Follow us to see blog updates, post updates, and new features
          </p>
          <p className="social_icons_container">
            <a
              href={"https://www.facebook.com/people/Amrityum/100077085990115"}
              target={"_blank"}
            >
              <i
                className="fab fa-facebook-f share_icon"
                style={{ padding: "9px 13px", color: "#0f92f3" }}
              ></i>
            </a>
            <a href={"https://www.instagram.com/amrityum/"} target={"_blank"}>
              <i
                className="fab fa-instagram share_icon"
                style={{ padding: "9px", color: "#d02f7e" }}
              ></i>
            </a>
            <a
              href={
                "https://www.linkedin.com/in/amrityum-lifelog-matters-8a9027236"
              }
              target={"_blank"}
            >
              <i
                className="fab fa-linkedin share_icon"
                style={{ padding: "9px", color: "#0077b5" }}
              ></i>
            </a>
            <a
              href={
                "https://www.youtube.com/channel/UCIPjOEE_yeWIl5O8qUuQnAw/featured"
              }
              target={"_blank"}
            >
              <i
                className="fab fa-youtube share_icon"
                style={{ padding: "9px", color: "#ff0000" }}
              ></i>
            </a>
            <a
              href={"https://api.whatsapp.com/send?phone=7558900111"}
              target={"_blank"}
            >
              <i
                className="fab fa-whatsapp share_icon"
                style={{ padding: "9px 11px", color: "#40bf50" }}
              ></i>
            </a>
            {/* <i className="fab fa-instagram share_icon"></i> */}
            <a href={"https://x.com/amrityum"} target={"_blank"}>
              <img src={x_logo} className="share_icon" style={{height: "42px",padding: "12px 11px" }} />
            </a>
            <a
              href={"https://in.pinterest.com/contactamrityum"}
              target={"_blank"}
            >
              <i
                className="fab fa-pinterest share_icon"
                style={{ padding: "9px", color: "#f5030f" }}
              ></i>
            </a>
          </p>
          <p>
            Would you like your business to be listed on our Service Directory?{" "}
            <Link
              className="page_link"
              to={"/Services"} target={"_blank"}
            >
              Click here.
            </Link>
          </p>
          <p>
            Amrityum may use your contact information to provide you with
            materials that we believe are of interest. You may choose to opt-out
            of receiving additional materials at any time by sending an email to{" "}
            <span>
              <a href="mailto:info@amrityum.com">info@amrityum.com</a>
            </span>
          </p>
        </div>
      </div>
    );
  }
}

export default ContactUs;
ContactUs.contextType = MyContext;
