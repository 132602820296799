import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import MyContext from "../MyContext";
import $ from "jquery";
import logo from "../../res/images/Amrityum_01.png";
import white_logo from "../../res/images/Amrityum_03.png";
import user_img from "../../res/images/user_img.jpg";
import { faDesktop, faHandHoldingWater, faHandshake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AiOutlineHome, AiOutlinePieChart } from "react-icons/ai";
import { MdKeyboardArrowDown, MdOutlinePermPhoneMsg } from "react-icons/md";
import { IoCloseSharp, IoPawSharp } from "react-icons/io5";
import { FaUserShield, FaRegAddressCard, FaShieldAlt } from "react-icons/fa";
import { BsGrid } from "react-icons/bs";
import { CgComment } from "react-icons/cg";
import { RiServiceLine } from "react-icons/ri";
import { FiShoppingCart } from "react-icons/fi";
import { BiMessageSquareEdit } from "react-icons/bi";


class Header extends React.Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
    this.mobile_sidebar_ref = React.createRef();
    this.sidebar_active_menu = this.sidebar_active_menu.bind(this);
    this.heartbeat = this.heartbeat.bind(this);
  }

  componentDidMount() {
    this.show_hide_sidebar(this.props.history.location.pathname);
    this.unlisten = this.props.history.listen((location, action) => {
      this.show_hide_sidebar(location.pathname);
      this.active_menu(location.pathname);
      if (this.mobile_sidebar_ref.current) {
        this.mobile_sidebar_ref.current.style.width = "0px";
      }
     
    });
    var obj_this = this;
    // console.log(this.context.session);
    $(".mob_menu").on("click", function () {
      obj_this.mobile_sidebar_ref.current.style.width = 0;
    });


    $(".sub_menu").click(function () {
      $(".sub_menu").removeClass("active");
      $(".items").slideUp();
      if (!$(this).next().is(":visible")) {
        $(this).next().slideDown();
        // $(this).addClass("active");
      }
    })
    $(".mob_menu_container .main_menu").on("click", function () {
      $(".items").css("display", "none");
      $(".mob_menu_container .sub_menu").removeClass("selected_mob_menu");
      console.log($(this).next().hasClass("items"));
    });
    
    // Every 5 seconds, the heartbeat API is called. If the user logs out, the API's timeout is cleared, and it can continue to be called without affecting the network connectivity.
    if(this.context.session)
      this.heartbeat();

    $(document).on("click", ".url_scroll_up",function(){
      if(window.location.pathname == $(this).attr("href")){
        window.scroll({
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        });
        $(".scroll_top_button").css({
          "opacity" : 0,
          "visibility" : "hidden"
        });
      }
    });

    $(document).on("click",".content_refresh", function(){
      $($(this).attr("reset_elem")).trigger("click");
      window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
      // $(".scroll_top_button").css({
      //   "opacity" : 0,
      //   "visibility" : "hidden"
      // });
    });
  }

  heartbeat(){
    // console.log("heartbeat from header");
    var obj_this = this;
    var heartbeat_timeout;
    this.context.heartbeat_XHR = $.ajax({
      url: process.env.REACT_APP_API + "/api/heartbeat",
      data: this.context.bind_session({}),
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data);
      if(return_data.status == "success"){
        if(window.location.href.includes("/Dashboard/Services/Edit")){
          if(return_data.session.user_type == "user"){
            obj_this.context.setSession(return_data.session);
            alert("We regret to inform you that your services have been deleted/rejected by the administrator. Please check your email for further information regarding the reason for this action");
            obj_this.props.history.replace('/Dashboard');
          }
        }
        if(obj_this.context.session)
          heartbeat_timeout = setTimeout(obj_this.heartbeat,5000);
        obj_this.context.setSession(return_data.session);
      }else if(return_data.status == "deleted"){
        obj_this.context.loading(0);
        obj_this.context.delete_session();
        alert("Sorry, your account has been deleted. Contact Administrator");
        obj_this.props.history.replace('/');
      }else{
        obj_this.context.loading(0);
        obj_this.context.delete_session();
        // alert("Your session has timed out. Please login again.");
        // obj_this.props.history.replace("/Login");
      }
    }).fail(function(jqXHR,textStatus, errorThrown){
      console.log(errorThrown);
      if(errorThrown == "abort"){
        console.log("aborting");
        clearTimeout(heartbeat_timeout);
      }else{
        setTimeout(obj_this.heartbeat,5000);
      }
    });
    // this.context.heartbeat_XHR.abort();

  }

  active_menu(path) { 
    // console.log(path);
    this.sidebar_active_menu(path);
    path = path.split("/");
    $(".header_menu").removeClass("selected_header_menu");
    $(".mob_menu").removeClass("selected_mob_menu");
    // console.log(path);
    $(".header_menu[href='/" + path[1] + "']").addClass("selected_header_menu");
    $(".mob_menu[href='/" + path[1] + "']").addClass("selected_mob_menu");
    if ($(".mob_menu_container .items:visible").length) {
      $(".mob_menu_container .sub_menu").addClass("selected_mob_menu");
    } else {
      $(".mob_menu_container .sub_menu").removeClass("selected_mob_menu");
    }
    $(".dropdown-content .header_menu").removeClass("selected_header_menu").css("color", "");
    $(".dropdown-content .header_menu[href='/" + path[1] + "']").css("color", "#9100b7e6");
    
    // if(this.context.session){
    //   clearInterval(this.heartbeatInterval);
    //   this.heartbeatInterval = setInterval(this.heartbeat,20000);
    // }else
    //   clearInterval(this.heartbeatInterval);
  }

  sidebar_active_menu(path){
    if($('.sidebar_component_container .menu_item[href="' + path + '"]').length){
      $(".menu_container a").removeClass("selected_sidebar_menu");
      $(".menu_container a[href='" + path + "']").addClass("selected_sidebar_menu");
      $(".sidebar_component_container .menu_item").css({
        "background-color": "",
        "color" : ""
      });
  
      $(".sidebar_component_container .menu_list").css({
        "height": "0"
      });
      if($('.sidebar_component_container .menu_item[href="' + path + '"]').parent().hasClass("menu_container")){
        $('.sidebar_component_container .menu_item[href="' + path + '"]').css({
          "background-color": "#613282bd",
          "color" : "white"
        });
      }else{  
        $('.sidebar_component_container .menu_item[href="' + path + '"]').css({
          "background-color": "#9100b76e",
          "color" : "white"
        });
        // $('.sidebar_component_container .menu_item[href="' + path + '"]').css({
        //   // "background-color": "#9100b76e",
        //   "color" : "#613282bd"
        // });
        // console.log($('.sidebar_component_container .menu_item[href="' + path + '"]').parent().length)
        if($('.sidebar_component_container .menu_item[href="' + path + '"]').parent().length){
          $('.sidebar_component_container .menu_item[href="' + path + '"]').parent().heightAuto();
          $('.sidebar_component_container .menu_item[href="' + path + '"]').parent().prev().css({
            backgroundColor : "#613282bd",
            color :"white"
          });
        }else{
          
        }
      }
    }
  }

  show_hide_sidebar(path) {
    // console.log(path);
    var show_mobile_sidebar = 0;
    var show_sidebar = 0;
    // console.log($(window).width());
    if ((/^\/Dashboard/.test(path))) {
      if (!this.context.session) {
        this.props.history.replace({
          pathname: '/Login',
          url: this.props.history.location.pathname
        });
        // console.log("session ila");
        return;
      }
      if ($(window).width() < 880) {
        show_mobile_sidebar = 1;
      } else {
        show_sidebar = 1;
      }
      // console.log(show_sidebar,show_mobile_sidebar)
      this.context.sidebar(show_sidebar, show_mobile_sidebar);
    } else {
      // console.log("venda");
      this.context.sidebar(show_sidebar, show_mobile_sidebar);
    }
  }

  logout() {
    if (!window.confirm("Are you sure you want to logout")) {
      return;
    }
    
    this.context.heartbeat_XHR.abort();
    this.context.loading(1);
    // console.log(session);

    var obj_this = this;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/logout",
      data: {
        sid: this.context.session.sid
      },
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);

      if (return_data.status == "success") {
        obj_this.context.delete_session();
        obj_this.context.loading(0);
        obj_this.props.history.replace({ pathname: '/', type: "logout" });
        $.alert("Logged out successfully");
      } else {
        obj_this.context.loading(0);
        alert("Network error!!");
        obj_this.props.history.replace({ pathname: '/', type: "logout" });
      }
    }).fail(function () {
      alert("Network error!!");
      obj_this.props.history.replace({ pathname: '/', type: "logout" });
    });
  }

  render() {
    return (
      <MyContext.Consumer>
        {context => (
          <div className="header_container">
            <Link to="/" className="logo_image_container url_scroll_up" href="/">
              <img className="logo_image" src={logo} />
              <img className="white_logo_image" src={white_logo} />
            </Link>
            <div className="spacer"></div>
            <div className="header_menu_list">
              <Link className="header_menu url_scroll_up" to="/">HOME</Link>
              {/* <Link className="header_menu url_scroll_up" to="/AboutUs">ABOUT US</Link> */}
              <div className="dropdown">
                <button className="drop_btn">ABOUT &nbsp;
                  <i className="fa fa-caret-down"></i>
                </button>
                <div className="dropdown-content">
                  <Link className="header_menu url_scroll_up" to="/AboutUs">ABOUT US</Link>
                  <Link className="header_menu url_scroll_up services_refresh" to="/Services">SERVICE DIRECTORY</Link>
                  <Link className="header_menu url_scroll_up blogs_refresh" to="/Blogs">BLOGS</Link>
                  <Link className="header_menu url_scroll_up" to="/PartnerInformation">PARTNER INFORMATION</Link>
                  <Link className="header_menu url_scroll_up" to="/ContactUs">CONTACT US</Link>
                </div>
              </div>
              <Link className="header_menu url_scroll_up content_refresh" to="/DeathNotices" reset_elem=".death_notices_page_container .reset_form_button">DEATH NOTICES</Link>
              <Link className="header_menu url_scroll_up content_refresh" to="/Memorials" reset_elem=".memorials_page_container .reset_form_button">MEMORIALS</Link>
              <Link className="header_menu url_scroll_up content_refresh" to="/InMemoryNotices" reset_elem=".familynotice_page_container .reset_form_button">IN MEMORY NOTICES</Link>
              <Link className="header_menu url_scroll_up pet_tribute_refresh" to="/PetTributes" reset_elem=".pet_tributes_page_container .reset_form_button">PET TRIBUTES</Link>
              <a className="header_menu url_scroll_up" href="/ComingSoon">SHOP</a>
              <Link className="header_menu url_scroll_up charity_refresh" to="/Charities">CHARITY</Link>
              {this.context.session ?
                <div className="account_button">
                  {this.context.session.image_src ?
                    (!this.context.session.login_type) ?
                      <div className="profile_image" style={{ backgroundImage: "url('" + process.env.REACT_APP_CDN_URL + "/user_profile_images/" + this.context.session.image_src + "')" }}></div>
                      :
                      <img className="profile_image" src={this.context.session.image_src} referrerPolicy="no-referrer" />
                    :
                    <div className="profile_image" style={{ backgroundImage: "url('" + user_img + "')" }}></div>
                  }
                  {/* <i className="far fa-user-circle account_icon"></i> */}
                  <div className="web_menu_popup">
                    <div className="web_menu_user">{this.context.session ? this.context.session.fullname : ""}</div>
                    <Link className="web_menu url_scroll_up" to="/Dashboard"><i className="fas fa-tachometer-alt"></i>&nbsp;Dashboard</Link>
                    <div className="web_menu" onClick={this.logout}><i className="fas fa-sign-out-alt"></i>&nbsp;Logout</div>
                  </div>
                </div>
                :
                <Link className="signin_signup_button url_scroll_up"
                  to={{
                    pathname: "/Login",
                    url: this.props.history.location.pathname
                  }}
                  style={{ display: !this.context.session ? "inline-block" : "none" }}
                >Sign In / Sign Up</Link>
              }
              {this.context.session ?""
              :
              <>
              <div className="register_button">
                New to Amrityum ? &nbsp;
                <Link to="/Register" className="user_registration url_scroll_up">Register</Link>
              </div>
              <div className="login_button_container">
                Already have an account ?&nbsp;
                <Link to="/Login" className="login_link url_scroll_up">Login</Link>
              </div>
              </>}
              
            </div>

            <div className="ham_icon" onClick={() => { this.mobile_sidebar_ref.current.style.width = "320px" }}><i className="fas fa-bars"></i></div>
            <div className="mobile_sidebar_container" ref={this.mobile_sidebar_ref}>
              <div className="mobile_sidebar">
                <div className="close_mobile_sidebar"><IoCloseSharp className="mob_close_button" onClick={() => { this.mobile_sidebar_ref.current.style.width = "0px" }}></IoCloseSharp></div>
                <div className="mob_menu_container">
                  {this.context.session ?
                    <div className="user_container">
                      {this.context.session.image_src ?
                        (!this.context.session.login_type) ?
                          <div className="profile_image" style={{ backgroundImage: "url('" + process.env.REACT_APP_CDN_URL + "/user_profile_images/" + this.context.session.image_src + "')" }}></div>
                          :
                          <img className="profile_image" src={this.context.session.image_src} referrerPolicy="no-referrer" />
                        :
                        <div className="profile_image" style={{ backgroundImage: "url('" + user_img + "')" }}></div>
                      }
                      <div className="user_name">{this.context.session.fullname}</div>
                    </div>
                    :
                    <Link className="default_container url_scroll_up" to="/">
                      <img className="logo_image" src={logo}/>
                    </Link>
                  }
                  <div className="scrollable_container">
                    <Link className="mob_menu url_scroll_up main_menu" to="/">
                      <div className="icon_container">
                        <div className="icon" style={{ backgroundColor: "#edf0f5" }}><AiOutlineHome color="white"></AiOutlineHome></div>
                      </div>
                      <span>Home</span>
                    </Link>
                    <div className="sub_menu">
                      <div className="icon_container">
                        <div className="icon" style={{ backgroundColor: "#edf0f5" }}><BsGrid color="white"></BsGrid></div>
                      </div>
                      <span>About</span>
                      <MdKeyboardArrowDown className="arrow_icon"></MdKeyboardArrowDown>
                    </div>
                    <div className="items">
                      <Link className="mob_menu url_scroll_up" to="/AboutUs">
                        <div className="icon_container">
                          <div className="icon" style={{ backgroundColor: "#edf0f5" }}><FaUserShield color="white"></FaUserShield></div>
                        </div>
                        <span>About Us</span>
                      </Link>
                      <Link className="mob_menu url_scroll_up blogs_refresh" to="/Blogs">
                        <div className="icon_container">
                          <div className="icon" style={{ backgroundColor: "#edf0f5" }}><BiMessageSquareEdit color="white"></BiMessageSquareEdit></div>
                        </div><span>Blogs</span></Link>
                      <Link className="mob_menu url_scroll_up services_refresh" to="/Services">
                        <div className="icon_container">
                          <div className="icon" style={{ backgroundColor: "#edf0f5" }}><FaShieldAlt color="white"></FaShieldAlt></div>
                        </div><span>Service Directory</span></Link>
                      <Link className="mob_menu url_scroll_up" to="/ContactUs">
                        <div className="icon_container">
                          <div className="icon" style={{ backgroundColor: "#edf0f5" }}><MdOutlinePermPhoneMsg color="white"></MdOutlinePermPhoneMsg></div>
                        </div><span>Contact Us</span></Link>
                    </div>
                    <Link className=" mob_menu url_scroll_up main_menu content_refresh" to="/DeathNotices" reset_elem=".death_notices_page_container .reset_form_button">
                      <div className="icon_container">
                        <div className="icon" style={{ backgroundColor: "#edf0f5" }}><FaRegAddressCard color="white"></FaRegAddressCard></div>
                      </div><span>Death Notices</span></Link>
                    <Link className=" mob_menu url_scroll_up main_menu content_refresh" to="/Memorials" reset_elem=".memorials_page_container .reset_form_button">
                      <div className="icon_container">
                        <div className="icon" style={{ backgroundColor: "#edf0f5" }}><FaRegAddressCard color="white"></FaRegAddressCard></div>
                      </div><span>Memorials</span></Link>
                    <Link className=" mob_menu url_scroll_up main_menu content_refresh" to="/InMemoryNotices" reset_elem=".familynotice_page_container .reset_form_button">
                      <div className="icon_container">
                        <div className="icon" style={{ backgroundColor: "#edf0f5" }}><FaRegAddressCard color="white"></FaRegAddressCard></div>
                      </div><span>In Memory Notices</span></Link>
                    <Link className=" mob_menu url_scroll_up main_menu pet_tribute_refresh" to="/PetTributes" reset_elem=".pet_tributes_page_container .reset_form_button">
                      <div className="icon_container">
                        <div className="icon" style={{ backgroundColor: "#edf0f5" }}><IoPawSharp color="white"></IoPawSharp></div>
                      </div><span>Pet Tributes</span></Link>
                    <a className=" mob_menu main_menu" href="/ComingSoon">
                      <div className="icon_container">
                        <div className="icon" style={{ backgroundColor: "#edf0f5" }}><FiShoppingCart color="white"></FiShoppingCart></div>
                      </div><span>Shop</span></a>
                    <Link className=" mob_menu url_scroll_up main_menu charity_refresh" to="/Charities">
                      <div className="icon_container">
                        <div className="icon" style={{ backgroundColor: "#edf0f5" }}><RiServiceLine color="white"></RiServiceLine></div>
                      </div><span>Charity</span></Link>
                    {this.context.session ?
                      <Link className=" mob_menu url_scroll_up main_menu" to="/Dashboard">
                        <div className="icon_container">
                          <div className="icon" style={{ backgroundColor: "#edf0f5" }}><AiOutlinePieChart color="white"></AiOutlinePieChart></div>
                        </div><span>Dashboard</span></Link>
                      : ""}
                  </div>
                  {this.context.session ?
                    <>
                      <div className="button_container">
                        <div className="notice_button_theme1"
                          onClick={() => { this.logout() }}>Logout
                        </div>
                      </div>
                    </> :
                    <>
                      <div className="button_container">
                        <div className="notice_button_theme1"
                          onClick={() => this.props.history.push("/Login")}>Login
                        </div>
                      </div>
                    </>}
                </div>
              </div>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    )
  }
}

export default Header;
Header.contextType = MyContext;