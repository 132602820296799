import React from "react";
import './SinglePetTribute.scss';
import { MdOutlineArrowForwardIos, MdOutlineKeyboardBackspace, MdOutlineShare, MdOutlinePrint } from 'react-icons/md';
import { FaRegComment, FaUserCircle } from 'react-icons/fa';
import $ from "jquery";
import MyContext from "../MyContext";
import { GoReport } from "react-icons/go";
import { ButtonTheme1, ButtonTheme2 } from "../../res/styler/ButtonThemes/ButtonThemes";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { AiFillHeart } from "react-icons/ai";
import user_img from "../../res/images/user_img.jpg";
import x_logo from "../../res/images/X_logo_black.png";

class SinglePetTribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pet_tribute: {
        name: "",
        image_src1: "",
        entered_by: "",
        date_of_birth: "",
        date_of_death: ""
      },
      comment: "",
      comments: [],
      reason: "",
      preview: "",
      reject_reason: "",
    }

    this.single_pet_tribute_page_ref = React.createRef();
    this.reject_reason_container_ref = React.createRef();
    this.form_change = this.form_change.bind(this);
    this.bind_likes = this.bind_likes.bind(this);
    this.show_comment_form = this.show_comment_form.bind(this);
    this.comment_form_submit = this.comment_form_submit.bind(this);
    this.report_message = this.report_message.bind(this);
    this.on_like_button_click = this.on_like_button_click.bind(this);
    this.show_report_page_form = this.show_report_page_form.bind(this);
    this.report_page_form_submit = this.report_page_form_submit.bind(this);
    this.approve_pet_tribute = this.approve_pet_tribute.bind(this);
    this.reject_pet_tribute = this.reject_pet_tribute.bind(this);
    this.reject_form_submit = this.reject_form_submit.bind(this);
    this.month_names = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    this.user_likes = [];
    this.user_like_count = [];
  }

  componentDidMount() {
    this.context.loading(1);
    var obj_this = this;
    if (window.location.pathname == "/PetTribute/Preview/" + this.props.match.params.pet_tribute_id) {
      $.ajax({
        url: process.env.REACT_APP_API + "/api/previewPetTributeDetails",
        data: this.context.bind_session({
          'pet_tribute_id': this.props.match.params.pet_tribute_id,
          'email': this.context.session.email
        }),
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          obj_this.user_likes = return_data.user_likes;
          obj_this.user_like_count = return_data.user_like_count;
          const comments = return_data.comments;
          obj_this.bind_likes(comments, "comment");
          obj_this.setState({
            pet_tribute: return_data.pet_tribute,
            comments: comments,
            preview: 1
          }, () => {
            obj_this.context.loading(0);
            obj_this.single_pet_tribute_page_ref.current.style.visibility = "visible";
            obj_this.single_pet_tribute_page_ref.current.style.opacity = 1;
            $(".header_container").css("pointer-events", "none");
            $(".like_button,.single_charity, .report_button, .button_theme2_container,.all_charity_button, .new_comment_button, .donate_now_button, .custom_button, .shop_now_button, .save_message_button").css("pointer-events", "none");
            $(".buttons_container, .footer_container").css("display", "none")
            $("html,body").animate({ scrollTop: 0 }, "slow");
          });
        } else {
          alert("Network error!!");
        }
        obj_this.context.loading(0);
      }).fail(function () {
        alert("Network error!!");
      });
    } else {
      $.ajax({
        url: process.env.REACT_APP_API + "/api/getPetTributeDetails",
        data: {
          'pet_tribute_id': this.props.match.params.pet_tribute_id,
          'email': this.context.session.email
        },
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          if (return_data.pet_tribute.status == "DELETED") {
            obj_this.props.history.push('/PetTributes')
          }
          obj_this.user_likes = return_data.user_likes;
          obj_this.user_like_count = return_data.user_like_count;
          const comments = return_data.comments;
          obj_this.bind_likes(comments, "comment");
          obj_this.setState({
            pet_tribute: return_data.pet_tribute,
            comments: comments
          }, () => {
            obj_this.context.loading(0);
            obj_this.single_pet_tribute_page_ref.current.style.visibility = "visible";
            obj_this.single_pet_tribute_page_ref.current.style.opacity = 1;
            $("html,body").animate({ scrollTop: 0 }, "slow");
            obj_this.props.history.replace("/PetTributes/" + obj_this.state.pet_tribute.id + "/" + obj_this.state.pet_tribute.name.replace(/\s+/g, '-') + "/" + obj_this.state.pet_tribute.serial_no);
          });
        } else {
          alert("Network error!!");
        }
        obj_this.context.loading(0);
      }).fail(function () {
        alert("Network error!!");
      });
    }
  }

  bind_likes(messages, message_type) {
    //this function bind likes count and check whether the message is liked or not by logined user
    messages.map((message) => {
      message.liked = 0;
      message.like_count = 0;
      this.user_likes.map((item) => {
        if (item.content_type == message_type) {
          if (item.content_id == message.id) {
            if (this.context.session) {
              message.liked = 1;
            }
          }
        }
      });
      this.user_like_count.map((item) => {
        if (item.content_type == message_type) {
          if (item.content_id == message.id) {
            message.like_count = item.like_count;
          }
        }
      });
    });
  }

  form_change(e) {
    if (e.target.value.length > 200) {
      $.alert("Maximum characters exceeded.")
      return;
    }
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentWillUnmount() {
    $(".footer_container").css("display", "block");
    $(".header_container").css("pointer-events", "auto")
  }

  show_comment_form() {
    if (this.context.session) {
      $(".add_message_form_ghost").css("display", "block");
    } else {
      this.context.confirm("Sign in to leave a comment", () => {
        this.props.history.push({ pathname: "/Login", url: "/PetTributes/" + this.props.match.params.pet_tribute_id })
      });
      return;
    }
  }

  comment_form_submit(e) {
    e.preventDefault();
    // console.log(this.state.comment);
    var obj_this = this;
    this.context.loading(1);
    $(".add_message_form_ghost").css("display", "none");
    $.ajax({
      url: process.env.REACT_APP_API + "/api/savePetTributeComment",
      data: this.context.bind_session({
        pet_tribute_id: this.props.match.params.pet_tribute_id,
        comment: this.state.comment,
        email: this.context.session.email
      }),
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      if (return_data.status == "success") {
        obj_this.user_likes = return_data.user_likes;
        obj_this.user_like_count = return_data.user_like_count;
        const comments = return_data.comments;
        obj_this.bind_likes(comments, "comment");
        obj_this.setState({
          comments: comments,
          comment: ""
        });
        if (return_data.comment_status == "PUBLISHED") {
          $.alert("Your message is published");
        } else {
          $.alert("Your message is waiting for administrator approval, Please check back later.");
        }
      } else {
        alert("Network error")
      }
      obj_this.context.loading(0);
    }).fail(function () {
      alert("Network error")
    });
  }

  show_report_page_form() {
    if (this.context.session) {
      $(".report_page_form_ghost").css("display", "block");
    } else {
      this.context.confirm("Sign in to report this page", () => {
        this.props.history.push({ pathname: "/Login", url: "/PetTributes/" + this.props.match.params.pet_tribute_id })
      });
      return;
    }
  }

  report_page_form_submit(e) {
    e.preventDefault();
    console.log(this.state.reason);
    // return;
    var obj_this = this;
    this.context.loading(1);
    $(".report_page_form_ghost").css("display", "none");
    const reported_data = {
      notice_id: this.props.match.params.pet_tribute_id,
      notice_type: "pet_tribute",
      reason: this.state.reason
    };
    $.ajax({
      url: process.env.REACT_APP_API + "/api/reportNotice",
      data: this.context.bind_session({
        reported_data: JSON.stringify(reported_data)
      }),
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      if (return_data.status == "success") {
        obj_this.setState({
          reason: ""
        });
        $.alert("Your message is waiting for administrator approval. Please check back later.");
      } else {
        $.alert("Something Went Wrong, Please Try Again");
      }
      obj_this.context.loading(0);
    }).fail(function () {
      alert("Network error")
    });
  }

  report_message(id) {
    if (!this.context.session) {
      this.context.confirm("Sign in to continue", () => {
        this.props.history.push({ pathname: "/Login", url: "/PetTributes/" + this.props.match.params.pet_tribute_id })
      });
      return;
    } else {
      this.context.confirm("Report this comment", () => {
        var obj_this = this;
        this.context.loading(1);
        const report_data = {
          pet_tribute_id: this.state.pet_tribute.id,
          content_id: id,
          content_type: "comment"
        }
        $.ajax({
          url: process.env.REACT_APP_API + "/api/reportPetTributeContent",
          data: this.context.bind_session({ report_data: JSON.stringify(report_data) }),
          type: "POST"
        }).done(function (return_data) {
          if (return_data.status == "success") {
            obj_this.context.loading(0);
            $.alert("Your request will be reviewed.");
          } else {
            alert("Network error!!");
          }
        }).fail(function (return_data) {
          alert("Network error!!");
        });
      });
    }
  }

  on_like_button_click(id, type, e) {

    if (!this.context.session) {
      this.context.confirm("Sign in to continue", () => {
        this.props.history.push({ pathname: "/Login", url: "/PetTributes/" + this.props.match.params.pet_tribute_id })
      });
      return;
    }
    // console.log(this.context.session);
    // return;
    // console.log($(e.currentTarget).find(".like_button_label").html())
    if ($(e.currentTarget).find(".like_button_label").html() == "Like") {
      //like
      var obj_this = this;
      this.context.loading(1);
      const liked_content = {
        notice_type: "pet_tribute",
        content_id: id,
        content_type: type
      }
      $.ajax({
        url: process.env.REACT_APP_API + "/api/likeContent",
        data: this.context.bind_session({ liked_content: JSON.stringify(liked_content) }),
        type: "POST"
      }).done(function (return_data) {
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          if (type == "comment") {
            const comments = obj_this.state.comments;
            comments.map((item) => {
              if (item.id == id) {
                item.liked = 1;
                ++item.like_count;
              }
            });
            obj_this.setState({
              comments: comments
            });
          }
        } else if (return_data.status == "session failed") {
          obj_this.props.history.push({ pathname: "/Login", url: "/PetTributes/" + obj_this.props.match.params.pet_tribute_id })
        } else {
          alert("Network error!!");
        }
      }).fail(function (return_data) {
        alert("Network error!!");
      });
    } else {
      //dislike
      var obj_this = this;
      this.context.loading(1);
      const disliked_content = {
        notice_type: "pet_tribute",
        content_id: id,
        content_type: type
      }
      $.ajax({
        url: process.env.REACT_APP_API + "/api/disLikeContent",
        data: this.context.bind_session({ disliked_content: JSON.stringify(disliked_content) }),
        type: "POST"
      }).done(function (return_data) {
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          if (type == "comment") {
            const comments = obj_this.state.comments;
            comments.map((item) => {
              if (item.id == id) {
                item.liked = 0;
                --item.like_count
              }
            });
            obj_this.setState({
              comments: comments
            });
          }
        } else if (return_data.status == "session failed") {
          obj_this.props.history.push({ pathname: "/Login", url: "/DeathNotices/" + obj_this.props.match.params.death_notice_id })
        } else {
          alert("Network error!!");
        }
      }).fail(function (return_data) {
        alert("Network error!!");
      });
    }
  }

  reject_pet_tribute() {
    if (!window.confirm("Reject this notice")) {
      return;
    }
    this.reject_reason_container_ref.current.style.display = "block";

  }

  reject_form_submit(e) {
    e.preventDefault();
    var obj_this = this
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/rejectPetTribute",
      data: this.context.bind_session({ pet_tribute_id: this.state.pet_tribute.id, reject_reason: this.state.reject_reason }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        alert("Pet tribute rejected.");
        obj_this.props.history.push({
          pathname: obj_this.props.location.url ?? "/Dashboard/Dashboard",
          state: obj_this.props.location.previousState ?? ""
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  approve_pet_tribute() {
    if (!window.confirm("Approve this notice")) {
      return;
    }
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/approvePetTribute",
      data: this.context.bind_session({ pet_tribute_id: this.state.pet_tribute.id }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        alert("Pet tribute approved.");
        obj_this.props.history.push({
          pathname: obj_this.props.location.url ?? "/Dashboard/Dashboard",
          state: obj_this.props.location.previousState ?? ""
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }

  render() {
    return (
      <div className="single_pet_tribute_page_container page_container" ref={this.single_pet_tribute_page_ref}>
        {/* <h1 className="notice_type">ANNIVERSARY</h1> */}
        <div className="pet_tribute_page_ghost"></div>
        <div className="preview_title" style={{ display: this.state.preview ? "block" : "none" }}>Preview</div>
        <div className="pet_tribute_container">
          {/* <div className="faithful_friend">Faithful friend</div> */}
          {/* <div  className="pet_memorial">Pet memorial</div> */}
          <div className="image_details_grid">
            <div className="image_container">
              <img className="notice_image" src={process.env.REACT_APP_CDN_URL + "/" + this.state.pet_tribute.entered_by + "/pet_tribute_images/" + this.state.pet_tribute.image_src1} />
              {/* <div className="notice_image" style={{
                display : this.state.pet_tribute.image_src2?"inline-block":"none",
                backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.pet_tribute.entered_by+"/pet_tribute_images/"+this.state.pet_tribute.image_src2+"')"}}></div>
              <div className="notice_image" style={{
                display : this.state.pet_tribute.image_src3?"inline-block":"none",
                backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/"+this.state.pet_tribute.entered_by+"/pet_tribute_images/"+this.state.pet_tribute.image_src3+"')"}}></div> */}
              <div className="pet_name">{this.state.pet_tribute.name}</div>
              <div className="pet_age">{this.state.pet_tribute.age}</div>
              <div className="pet_breed">{this.state.pet_tribute.breed} {this.state.pet_tribute.gender ? "(" + this.state.pet_tribute.gender + ")" : ""}</div>
            </div>
            <div className="about_pet_container">
              <div className="pet_details_grid">
                <div className="pet_tribute_grid_item">
                  <div className="pet_title">Pet Names</div>
                  <div className="pet_content">{this.state.pet_tribute.pet_name}</div>
                </div>
                <div className="pet_tribute_grid_item">
                  <div className="pet_title">Favourite Food</div>
                  <div className="pet_content">{this.state.pet_tribute.favourite_food}</div>
                </div>
                <div className="pet_tribute_grid_item">
                  <div className="pet_title">Favourite Place</div>
                  <div className="pet_content">{this.state.pet_tribute.favourite_place}</div>
                </div>
                <div className="pet_tribute_grid_item">
                  <div className="pet_title">Favourite Activity</div>
                  <div className="pet_content">{this.state.pet_tribute.favourite_activity}</div>
                </div>
                <div className="pet_tribute_grid_item">
                  <div className="pet_title">Born</div>
                  <div className="pet_content">{this.state.pet_tribute.date_of_birth ? new Date(this.state.pet_tribute.date_of_birth.replace(/-/g, "/")).toLocaleString(new Date().getTimezoneOffset(), {
                    day: 'numeric', month: 'long', year: 'numeric'
                  }) : ""}</div>
                </div>
                <div className="pet_tribute_grid_item" style={{ display: this.state.pet_tribute.date_of_death ? "block" : "none" }}>
                  <div className="pet_title">Passed Away</div>
                  <div className="pet_content">{new Date(this.state.pet_tribute.date_of_death.replace(/-/g, "/")).toLocaleString(new Date().getTimezoneOffset(), {
                    day: 'numeric', month: 'long', year: 'numeric'
                  })}</div>
                </div>
                <div className="pet_tribute_grid_item">
                  <div className="pet_title">Likes</div>
                  <div className="pet_content">{this.state.pet_tribute.likes}</div>
                </div>
                <div className="pet_tribute_grid_item">
                  <div className="pet_title">Dislikes</div>
                  <div className="pet_content">{this.state.pet_tribute.dislikes}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="basic_details_container">
            <div className="about_pet_label">About <span style={{ color: "green" }}>{this.state.pet_tribute.name}</span></div>
            <div className="notice_content" dangerouslySetInnerHTML={{ __html: this.state.pet_tribute.content }} />
            <div style={{ textAlign: "right", color: "#690090", fontSize: "11px" }}>AMRITYUM ID : {this.state.pet_tribute.serial_no}</div>
          </div>

        </div>
        <div className="buttons_container">
          <div className="back_button custom_button"
            onClick={() => this.props.history.goBack()}
          >
            <div className="icon_container">
              <MdOutlineKeyboardBackspace />
            </div>
            <div className="button_label">Back</div>
            {/* <MdOutlineArrowForwardIos size={18}  className="arrow_icon"/> */}
          </div>
          <div className="report_page_button custom_button"
            onClick={this.show_report_page_form}
          >
            <div className="icon_container">
              <GoReport className="icon" />
            </div>
            <div className="button_label">Report this page</div>
            {/* <MdOutlineArrowForwardIos size={18}  className="arrow_icon"/> */}
          </div>
          <div className="share_button custom_button">
            <div className="icon_container">
              <MdOutlineShare className="icon" />
            </div>
            <div className="button_label">Share</div>
            {/* <MdOutlineArrowForwardIos size={18}  className="arrow_icon"/> */}
            <div className="share_buttons_container">
              <a href={"http://www.facebook.com/share.php?u=" + encodeURIComponent(process.env.REACT_APP_SHARE_URL + "/PetTributes/" + this.state.pet_tribute.id + "/" + this.state.pet_tribute.name.replace(/\s+/g, '-') + "/" + this.state.pet_tribute.serial_no)} target={"_blank"}>
                <i className="fab fa-facebook-f share_icon" style={{ color: "#0f92f3" }}></i>
                <span>Facebook</span>
              </a>
              <a href={"https://api.whatsapp.com/send?text=" + this.state.pet_tribute.name + " " + encodeURIComponent(process.env.REACT_APP_SHARE_URL + "/PetTributes/" + this.state.pet_tribute.id + "/" + this.state.pet_tribute.name.replace(/\s+/g, '-') + "/" + this.state.pet_tribute.serial_no)} data-action="share/whatsapp/share" target={"_blank"}>
                <i className="fab fa-whatsapp share_icon" style={{ color: "#40bf50" }}></i>
                <span>WhatsApp</span>
              </a>
              {/* <i className="fab fa-instagram share_icon"></i> */}
              <a href={"mailto:?subject=Pet Tribute of " + this.state.pet_tribute.name + "&body=" + this.state.pet_tribute.name + " " + process.env.REACT_APP_SHARE_URL + "/PetTributes/" + this.state.pet_tribute.id + "/" + this.state.pet_tribute.name.replace(/\s+/g, '-') + "/" + this.state.pet_tribute.serial_no} target={"_blank"}>
                <i className="far fa-envelope-open share_icon" style={{ color: "#d10a0a" }}></i>
                <span>Mail</span>
              </a>
              <a href={"https://x.com/intent/tweet?url=" + encodeURIComponent(process.env.REACT_APP_SHARE_URL + "/PetTributes/" + this.state.pet_tribute.id + "/" + this.state.pet_tribute.name.replace(/\s+/g, '-') + "/" + this.state.pet_tribute.serial_no)} target={"_blank"}>
                <img src={x_logo} className="share_icon" style={{ paddingLeft: "5px", height: "20px" }} />
                <span>X</span>
              </a>
              <a onClick={() => {
                var copyURL = process.env.REACT_APP_SHARE_URL + "/PetTributes/" + this.state.pet_tribute.id + "/" + this.state.pet_tribute.name.replace(/\s+/g, '-') + "/" + this.state.pet_tribute.serial_no;
                navigator.clipboard.writeText(copyURL);
                $.alert("URL copied to clipboard");
                setTimeout(() => {
                  $(".custom_alert_container .custom_alert_box .custon_alert_ok_button").trigger("click");
                }, 2000);
              }}>
                <i className="fa fa-clone share_icon" style={{ color: "grey" }}></i>
                <span>Copy URL</span>
              </a>
            </div>
          </div>

          <div className="print_button custom_button"
            onClick={() => { window.print(); }}
          >
            <div className="icon_container">
              <MdOutlinePrint />
            </div>
            <div className="button_label" >Print</div>
            {/* <MdOutlineArrowForwardIos size={18}  className="arrow_icon"/> */}
          </div>
        </div>
        <div className="comments_container">
          <div className="comments_label">
            <h1>Words of Support</h1>
            <div style={{ textAlign: "center" }}>
              <button className="new_comment_button"
                onClick={this.show_comment_form}
              >
                <div className="button_ghost"></div>
                <i className="fas fa-pencil-alt"></i>
                <span className="button_label"> Leave a Comment</span>
              </button>
            </div>
          </div>
          <Carousel
            additionalTransfrom={0}
            arrows
            centerMode={false}
            className="comments_slider"
            containerClass="slider_container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            autoPlay={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 2400,
                  min: 1080
                },
                items: 2,
                partialVisibilityGutter: 100
              },
              mobile: {
                breakpoint: {
                  max: 780,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 780
                },
                items: 2,
                partialVisibilityGutter: 30
              }
            }}
            showDots={true}
            sliderClass=""
            slidesToSlide={1}
            swipeable>
            {this.state.comments && this.state.comments.map((item, idx) => (
              <div className="single_comment" key={idx}>
                <div className="comment_header">
                  {item.user_image_src ?
                    (!item.login_type) ?
                      <div className="user_image" style={{ backgroundImage: "url('" + process.env.REACT_APP_CDN_URL + "/user_profile_images/" + item.user_image_src + "')" }}></div>
                      :
                      <img className="user_image" src={(item.user_image_src)} referrerPolicy="no-referrer" />
                    :
                    <div className="user_image" style={{ backgroundImage: "url('" + user_img + "')" }}></div>
                  }
                  <div className="comment_details">
                    <div className="commented_user">{item.fullname}</div>
                    <div className="user_location">{item.user_location}</div>
                    <div className="commented_date">{new Date(item.published_date).toLocaleString("en-IN", { weekday: "long" })}, {new Date(item.published_date).getDate()} {this.month_names[new Date(item.published_date).getMonth()]} {new Date(item.published_date).getFullYear()}</div>
                  </div>
                </div>
                <div className="comment_body">{item.comment}</div>
                <div className="like_report_grid">
                  <div className="likes_container">
                    <div className="like_button"
                      onClick={(e) => this.on_like_button_click(item.id, "comment", e)}
                      style={{ backgroundColor: item.liked ? "" : "#ff365f", color: item.liked ? "#ff365f" : "" }}
                    >
                      <div className="like_button_label">{item.liked ? "Liked" : "Like"}</div>
                      <AiFillHeart color="#ff365f" className="like_icon" style={{ color: item.liked ? '#ff365f' : "white" }} />
                    </div>
                    <span className="likes_count" style={{ display: item.like_count > 0 ? "block" : "none" }}> {item.like_count} {item.like_count > 1 ? "Likes" : "Like"}</span>
                  </div>
                  <div className="report_button" onClick={() => this.report_message(item.id, item.tribute_type)}>
                    <GoReport />
                    <div className="report_label">REPORT </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="approve_reject_buttons_container" style={{ display: this.state.preview ? "block" : "none" }}>
          <ButtonTheme1
            label="<< Back"
            className="previous_page_button"
            onClick={() => this.props.history.goBack()}
          ></ButtonTheme1>
          {this.state.pet_tribute.status == "PENDING" && this.context.session.user_type == "admin" ?
            <>
              <ButtonTheme1
                label="Reject"
                className="reject_button"
                onClick={() => { this.reject_pet_tribute() }}
                style={{ display: this.state.pet_tribute.status == "DELETED" ? "none" : "inline-block" }}
              ></ButtonTheme1>
              <ButtonTheme1
                label="Approve"
                className="approve_button"
                onClick={() => { this.approve_pet_tribute() }}
                style={{ display: this.state.pet_tribute.status == "APPROVED" ? "none" : "inline-block" }}
              ></ButtonTheme1></> : ""}
        </div>
        <div className="add_message_form_ghost"
        // onClick={() => $(".add_message_form_ghost").css("display","none")}
        >
          <form className="add_message_form"
            onClick={(e) => e.stopPropagation()}
            onSubmit={this.comment_form_submit}
          >
            <div className="add_message_title">Add your comment</div>
            <div className="leave_message_label">Leave a message of comfort for loved ones</div>
            <textarea
              name="comment"
              value={this.state.comment}
              rows={5}
              onChange={this.form_change}
              // maxLength={200}
              required
            />
            <div className="maximum_character_label">Max 200 characters</div>
            <ButtonTheme2 label="Send" type="submit" className="save_message_button" />
            <div className="close_message_form"
              onClick={() => {
                $(".add_message_form_ghost").css("display", "none");
                this.setState({
                  comment: ""
                })
              }}
            >
              <div>x</div>
            </div>
          </form>
        </div>
        <div className="report_page_form_ghost"
          onClick={() => $(".report_page_form_ghost").css("display", "none")}
        >
          <form className="report_page_form"
            onClick={(e) => e.stopPropagation()}
            onSubmit={this.report_page_form_submit}
          >
            <div className="report_page_title">Report this Notice</div>
            <div className="leave_message_label">Reasons for reporting notice</div>
            <textarea
              name="reason"
              value={this.state.reason}
              rows={5}
              onChange={this.form_change}
              maxLength={200}
              required
            />
            <div className="maximum_character_label">Max 200 characters</div>
            <ButtonTheme2 label="Send" type="submit" className="save_message_button" />
            <div className="close_message_form"
              onClick={() => {
                $(".report_page_form_ghost").css("display", "none");
                this.setState({
                  reason: ""
                })
              }}
            >
              <div>x</div>
            </div>
          </form>
        </div>
        <div className="reject_reason_container" ref={this.reject_reason_container_ref}>
          <form className="reject_reason_form" onSubmit={this.reject_form_submit} method="post">
            <div>Reject Reason</div>
            <textarea name="reject_reason"
              rows={4}
              value={this.state.reject_reason}
              onChange={this.form_change}
              required
            />
            <button className="reject_form_submit_button">Submit</button>
            <i className="fa fa-times reject_form_close_button" onClick={() => { this.reject_reason_container_ref.current.style.display = "none"; this.setState({ reject_reason: "" }) }}></i>
          </form>

        </div>
      </div>
    )
  }
}

export default SinglePetTribute;
SinglePetTribute.contextType = MyContext;