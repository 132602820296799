import React from "react";
import "./SingleBlog.scss";
import $ from "jquery";
import MyContext from "../MyContext";
import { MdOutlineKeyboardBackspace , MdOutlineShare, MdOutlinePrint} from 'react-icons/md';
import {FaRegComment,FaUserCircle } from 'react-icons/fa';
import {GoReport} from "react-icons/go";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {AiFillHeart} from "react-icons/ai";
import { ButtonTheme1, ButtonTheme2 } from "../../res/styler/ButtonThemes/ButtonThemes";
import user_img from "../../res/images/user_img.jpg";
import x_logo from "../../res/images/X_logo_black.png";

class SingleBlog extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      blog_details : {
        title : "",
        content : "",
        image_src  : "",
        published_date : "",
        entered_by : "",
      },
      comments : [],
      comment : "",
      preview : ""
    }
    this.form_change = this.form_change.bind(this);
    this.show_comment_form = this.show_comment_form.bind(this);
    this.comment_form_submit = this.comment_form_submit.bind(this);
    this.bind_likes = this.bind_likes.bind(this);
    this.on_like_button_click = this.on_like_button_click.bind(this);
    this.report_message = this.report_message.bind(this);
    this.single_blog_page_ref = React.createRef();
    this.user_likes = [];
    this.user_like_count = [];
  }

  componentDidMount(){
    var obj_this = this;
    this.context.loading(1);
    if(window.location.pathname == "/Blog/Preview/"+this.props.match.params.blog_id){
      $.ajax({
        url : process.env.REACT_APP_API+"/api/previewBlogDetails",
        data : this.context.bind_session({
          blog_id :this.props.match.params.blog_id
        }),
        type : "POST"
      }).done(function(return_data){
        if(return_data.status == "success"){
          obj_this.user_likes = return_data.user_likes;
          obj_this.user_like_count = return_data.user_like_count;
          const comments = return_data.comments;
          obj_this.bind_likes(comments,"comment");
          obj_this.setState({
            blog_details : return_data.blog_details,
            comments : return_data.comments,
            preview : 1
          },() => {
            $(".single_blog_page_container").css("visibility","visible");
            $(".header_container").css("pointer-events","none");
            $(".like_button,.single_charity, .report_button, .button_theme2_container,.all_charity_button, .new_comment_button, .donate_now_button, .custom_button, .shop_now_button, .save_message_button").css("pointer-events","none");
            $(".buttons_container, .footer_container").css("display","none");
            window.scrollTo({
              top : obj_this.single_blog_page_ref.current.offsetTop - 80,
              behavior : "smooth"
            });
          });
        }else{
          alert("Network error");
        }
        obj_this.context.loading(0);
      }).fail(function(){
        alert("Network error");
      })
    }else{
      $.ajax({
        url : process.env.REACT_APP_API+"/api/getBlogDetails",
        data : {
          blog_id :this.props.match.params.blog_id
        },
        type : "POST"
      }).done(function(return_data){
        if(return_data.status == "success"){
          obj_this.user_likes = return_data.user_likes;
          obj_this.user_like_count = return_data.user_like_count;
          const comments = return_data.comments;
          obj_this.bind_likes(comments,"comment");
          obj_this.setState({
            blog_details : return_data.blog_details,
            comments : return_data.comments
          },() => {
            $(".single_blog_page_container").css("visibility","visible");
            window.scrollTo({
              top : obj_this.single_blog_page_ref.current.offsetTop - 80,
              behavior : "smooth"
            });
            obj_this.props.history.replace("/Blogs/"+obj_this.state.blog_details.id+"/"+obj_this.state.blog_details.title.replace(/\s+/g, '-'));
          });
        }else{
          alert("Network error");
        }
        obj_this.context.loading(0);
      }).fail(function(){
        alert("Network error");
      })
    }
  }

  bind_likes(messages,message_type){
    //this function bind likes count and check whether the message is liked or not by logined user
    messages.map((message) => {
      message.liked = 0;
      message.like_count = 0;
      this.user_likes.map((item) => {
        if(item.content_type == message_type){
          if(item.content_id == message.id){
            if(this.context.session){
              message.liked = 1;
            }
          }
        }
      });
      this.user_like_count.map((item) => {
        if(item.content_type == message_type){
          if(item.content_id == message.id){
            message.like_count = item.like_count;
          }
        }
      });
    });
  }

  componentWillUnmount(){
    $(".footer_container").css("display","block");
    $(".header_container").css("pointer-events","auto")
  }

  form_change(e){
    if(e.target.value.length > 200){
      $.alert("Maximum characters exceeded.")
      return;
    }
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  show_comment_form(){
    if(this.context.session){
      $(".add_message_form_ghost").css("display","block");
    }else{
      this.context.confirm("Sign in to leave a comment",() => {
        this.props.history.push({ pathname: "/Login", url : "/Blogs/"+this.props.match.params.blog_id})
      });
      return;
    }
  }

  comment_form_submit(e){
    e.preventDefault();
    // console.log(this.state.comment);
    var obj_this = this;
    this.context.loading(1);
    $(".add_message_form_ghost").css("display","none");
    $.ajax({
      url : process.env.REACT_APP_API+"/api/saveBlogComment",
      data : this.context.bind_session({
        blog_id : this.props.match.params.blog_id,
        comment : this.state.comment,
        email : this.context.session.email
      }),
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.user_likes = return_data.user_likes;
        obj_this.user_like_count = return_data.user_like_count;
        const comments = return_data.comments;
        obj_this.bind_likes(comments,"comment");
        obj_this.setState({
          comments : comments,
          comment : ""
        });
        if(return_data.comment_status == "PUBLISHED"){
          $.alert("Your message is published");
        }else{
          $.alert("Your message is waiting for administrator approval, Please check back later.");
        }
      }else{
        alert("Network error")
      }
      obj_this.context.loading(0);
    }).fail(function(){
        alert("Network error")
    });
  }

  on_like_button_click(id,type,e){
    
    if(!this.context.session){
      this.context.confirm("Sign in to continue",() => {
        this.props.history.push({ pathname: "/Login", url : "/Blogs/"+this.props.match.params.blog_id})
      });
      return;
    }
    // console.log(this.context.session);
    // return;
    // console.log($(e.currentTarget).find(".like_button_label").html())
    if($(e.currentTarget).find(".like_button_label").html() == "Like"){
      //like
      var obj_this = this;
      this.context.loading(1);
      const liked_content = {
        notice_type: "blog" ,
        content_id : id,
        content_type : type
      }
      $.ajax({
        url: process.env.REACT_APP_API + "/api/likeContent",
        data: this.context.bind_session({liked_content : JSON.stringify(liked_content)}),
        type: "POST"
      }).done(function (return_data){
        // console.log(return_data);
        // return;
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          if(type == "comment"){
            const comments = obj_this.state.comments;
            comments.map((item) => {
              if(item.id == id){
                item.liked = 1;
                ++item.like_count;
              }
            });
            obj_this.setState({
              comments :comments
            });
          }
        }else if(return_data.status == "session failed"){
          obj_this.props.history.push({ pathname: "/Login", url : "/Blogs/"+obj_this.props.match.params.blogs_id})
        }else{
          alert("Network error!!");
        }
      }).fail(function (return_data) {
        alert("Network error!!");
      });
    }else{
      //dislike
      var obj_this = this;
      this.context.loading(1);
      const disliked_content = {
        notice_type: "blog" ,
        content_id : id,
        content_type : type
      }
      $.ajax({
        url: process.env.REACT_APP_API + "/api/disLikeContent",
        data: this.context.bind_session({disliked_content : JSON.stringify(disliked_content)}),
        type: "POST"
      }).done(function (return_data) {
        if (return_data.status == "success") {
          obj_this.context.loading(0);
           if(type == "comment"){
            const comments = obj_this.state.comments;
            comments.map((item) => {
              if(item.id == id){
                item.liked = 0;
                --item.like_count
              }
            });
            obj_this.setState({
              comments :comments
            });
          }
        }else if(return_data.status == "session failed"){
          obj_this.props.history.push({ pathname: "/Login", url : "/Blogs/"+obj_this.props.match.params.death_notice_id})
        }else {
          alert("Network error!!");
        }
      }).fail(function (return_data) {
        alert("Network error!!");
      });
    }
  }

  report_message(id){
    if(!this.context.session){
      this.context.confirm("Sign in to continue",() => {
        this.props.history.push({ pathname: "/Login", url : "/Blogs/"+this.props.match.params.blog_id})
      });
      return;
    }else{
      this.context.confirm("Report this comment",() => {
        var obj_this = this;
        this.context.loading(1);
        const report_data = {
          blog_id: this.state.blog_details.id ,
          content_id : id,
          content_type : "comment"
        }
        $.ajax({
          url: process.env.REACT_APP_API + "/api/reportBlogContent",
          data: this.context.bind_session({report_data : JSON.stringify(report_data)}),
          type: "POST"
        }).done(function (return_data) {
          if (return_data.status == "success") {
            obj_this.context.loading(0);
            $.alert("Your request will be reviewed.");
          } else {
            alert("Network error!!");
          }
        }).fail(function (return_data) {
          alert("Network error!!");
        });
      });
      return;
      
    }
  }

  render(){
    return(
      <div className="single_blog_page_container page_container" ref={this.single_blog_page_ref}>
        <div className="blog_container">
          <div className="blog_heading">{this.state.blog_details.title}</div>
          <img className="image_container"
            src={process.env.REACT_APP_CDN_URL+"/blog_images/"+this.state.blog_details.image_src}
            style={{
            display:this.state.blog_details.image_src?"block":"none"}} />
          <div className="published_details">
            Published by {this.state.blog_details.entered_by} on {new Date(this.state.blog_details.published_date).toLocaleString(new Date().getTimezoneOffset(),{
                weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
                })}
          </div>
          <div className="content" dangerouslySetInnerHTML={{ __html: this.state.blog_details.content }} />
        </div>
        <div className="buttons_container">
          <div className="back_button custom_button"
            onClick={() => this.props.history.goBack()}
          >
            <div className="icon_container">
              <MdOutlineKeyboardBackspace className="icon"/>
            </div>
            <div className="button_label">Back</div>
            {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
          </div>
          
          {/* <div className="report_page_button custom_button" 
            onClick={this.show_report_page_form}
          >
            <div className="icon_container">
              <GoReport className="icon"/>
            </div>
            <div className="button_label">Report this page</div>
          </div> */}
          <div className="share_button custom_button">
            <div className="icon_container">
              <MdOutlineShare className="icon"/>
            </div>
            <div className="button_label">Share</div>
            {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
            <div className="share_buttons_container">
              <a href={"http://www.facebook.com/share.php?u=" + encodeURIComponent(process.env.REACT_APP_API+"/Blogs/"+this.state.blog_details.id+"/"+this.state.blog_details.title.replace(/\s+/g, '-'))} target={"_blank"}>
                <i className="fab fa-facebook-f share_icon" style={{color:"#0f92f3"}}></i>
                <span>Facebook</span>
              </a>
              <a href={"https://api.whatsapp.com/send?text="+this.state.blog_details.title+" "+ encodeURIComponent(process.env.REACT_APP_API+"/Blogs/"+this.state.blog_details.id+"/"+this.state.blog_details.title.replace(/\s+/g, '-'))} data-action="share/whatsapp/share" target={"_blank"}>
                <i className="fab fa-whatsapp share_icon" style={{color:"#40bf50"}}></i>
                <span>WhatsApp</span>
              </a>
              {/* <i className="fab fa-instagram share_icon"></i> */}
              <a href={"mailto:?subject="+this.state.blog_details.title+"&body=" + process.env.REACT_APP_API+"/Blogs/"+this.state.blog_details.id+"/"+this.state.blog_details.title.replace(/\s+/g, '-')} target={"_blank"}>
                <i className="far fa-envelope-open share_icon" style={{color:"#d10a0a"}}></i>
                <span>Mail</span>
              </a>
              <a href={"https://x.com/intent/tweet?url=" + encodeURIComponent(process.env.REACT_APP_API+"/Blogs/"+this.state.blog_details.id+"/"+this.state.blog_details.title.replace(/\s+/g, '-'))} target={"_blank"}>
              <img src={x_logo} className="share_icon" style={{ paddingLeft: "5px", height: "20px" }} />
              <span>X</span>
              </a>
              <a onClick={() => {
                 var copyURL = process.env.REACT_APP_API+"/Blogs/"+this.state.blog_details.id+"/"+this.state.blog_details.title.replace(/\s+/g, '-');
                 navigator.clipboard.writeText(copyURL);
                 $.alert("URL copied to clipboard");
                 setTimeout(() => {
                   $(".custom_alert_container .custom_alert_box .custon_alert_ok_button").trigger("click");
                 },2000);
              }}>
                  <i className="fa fa-clone share_icon" style={{color:"grey"}}></i>
                <span>Copy URL</span>
              </a>
            </div>
          </div>

          <div className="print_button custom_button"
            onClick={() => window.print()}
          >
          <div className="icon_container">
            <MdOutlinePrint className="icon"/>
          </div>
            <div className="button_label">Print</div>
            {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
          </div>
        </div>
        <div className="comments_container">
          <div className="comments_label">
            <h1>Comments</h1>

            <div style={{textAlign:"center"}}>
              <button className="new_comment_button" 
                  onClick={this.show_comment_form}
              >
                <div className="button_ghost"></div>
                <i className="fas fa-pencil-alt"></i>
                <span className="button_label"> Leave a Comment</span>
              </button>
            </div>
          </div>
          <Carousel 
            additionalTransfrom={0}
            arrows
            centerMode={false}
            className="comments_slider"
            containerClass="slider_container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            autoPlay={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 2400,
                  min: 1080
                },
                items: 2,
                partialVisibilityGutter: 100
              },
              mobile: {
                breakpoint: {
                  max: 780,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 780
                },
                items: 2,
                partialVisibilityGutter: 30
              }
            }}
            showDots={true}
            sliderClass=""
            slidesToSlide={1}
            swipeable>
            {this.state.comments && this.state.comments.map((item,idx) => (
              <div className="single_comment" key={idx}>
              <div className="comment_header">
                 {item.user_image_src?
                  (!item.login_type)?
                    <div className="user_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/user_profile_images/"+item.user_image_src+"')"}}></div>
                    :
                    <img className="user_image" src={(item.user_image_src)} referrerPolicy="no-referrer"/>
                  :
                  <div className="user_image" style={{backgroundImage: "url('"+user_img+"')"}}></div> 
                }
                <div className="comment_details">
                  <div className="commented_user">{item.fullname}</div>
                  <div className="user_location">{item.user_location}</div>
                  <div className="commented_date">{new Date(item.published_date).toLocaleString(new Date().getTimezoneOffset(),{
                    weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
                    })}
                  </div>
                </div>
              </div>
              <div className="comment_body">{item.comment}</div>
              <div className="like_report_grid">
                <div className="likes_container">
                  <div className="like_button"
                    onClick={(e) => this.on_like_button_click(item.id,"comment",e)}
                    style={{backgroundColor:item.liked?"":"#ff365f",color:item.liked?"#ff365f":""}}
                  >
                    <div className="like_button_label">{item.liked?"Liked":"Like"}</div>
                    <AiFillHeart color="#ff365f" className="like_icon" style={{color:item.liked?'#ff365f':"white"}} />
                  </div>
                  <span className="likes_count" style={{display:item.like_count>0?"block":"none"}}> {item.like_count} {item.like_count>1?"Likes":"Like"}</span>
                </div>
                <div className="report_button" onClick={() => this.report_message(item.id,item.tribute_type)}> 
                  <GoReport />
                  <div className="report_label">REPORT </div> 
                </div>
              </div>
            </div>
            ))}
          </Carousel>
        </div>
        <div className="approve_reject_buttons_container" style={{display:this.state.preview?"block":"none"}}>
          <ButtonTheme1
              label="<< Back"
              className="previous_page_button"
              onClick={() => this.props.history.goBack()}
            ></ButtonTheme1>
          </div>
        <div className="add_message_form_ghost"
          // onClick={() => $(".add_message_form_ghost").css("display","none")}
        >
          <form className="add_message_form" 
            onClick={(e) => e.stopPropagation()}
            onSubmit={this.comment_form_submit}
          >
            <div className="add_message_title">Add a Message</div>
            <div className="leave_message_label">Leave a message of comfort for loved ones</div>
            <textarea
              name="comment"
              value={this.state.comment}
              rows={5}
              onChange={this.form_change}
              // maxLength={200}
              required
            />
            <div className="maximum_character_label">Max 200 characters</div>
            <ButtonTheme2 label="Send" type="submit" className="save_message_button" />
            <div className="close_message_form"
              onClick={() => {
                $(".add_message_form_ghost").css("display","none");
                this.setState({
                  comment : ""
                })
              }}
            >
              <div>x</div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default SingleBlog;
SingleBlog.contextType = MyContext;