import { Component } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import footer from "../../res/images/footer.png";
import { library } from '@fortawesome/fontawesome-svg-core';
import x_icon from "../../res/images/X_icon.png";

const twitterSvg = {
  prefix: 'fac', // Custom prefix
  iconName: 'twitter', // Custom name
  icon: [
    512,
    512,
    [],
    'f099', // Unique identifier
    'M512 97.25c-19.04 8.48-39.42 14.2-60.94 16.8 21.88-13.1 38.68-33.88 46.62-58.64-20.42 12.1-43.08 20.92-67.2 25.7C404.86 57.4 378.74 48 351.6 48c-53.02 0-95.92 43.8-95.92 97.8 0 7.68.8 15.24 2.5 22.48-79.88-4-150.74-42.36-198.3-100.66-8.3 14.3-13.06 31-13.06 48.84 0 33.74 17.12 63.64 43.16 81.06-15.84-.52-30.78-4.84-43.84-12v1.26c0 47.22 33.46 86.56 78.02 95.52-8.14 2.2-16.74 3.34-25.58 3.34-6.26 0-12.34-.6-18.22-1.74 12.36 38.48 48.32 66.48 90.82 67.26-33.3 26.1-75.24 41.66-120.74 41.66-7.8 0-15.56-.44-23.22-1.34 43.02 27.76 94.22 44 149.22 44 179.2 0 277.48-148.48 277.48-277.48 0-4.26-.1-8.46-.3-12.62 19.04-13.7 35.58-30.84 48.72-50.36z',
  ],
};

library.add(twitterSvg);

class Footer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (window.location.pathname.match(/Login/) || window.location.pathname.match(/Register/)) {
      return null;
    }
    return (
      <div className="footer_container" style={{ backgroundImage: "url('" + footer + "')" }}>
        <div className="page_links_container">
          <Link className="page_link url_scroll_up" to="/">Home</Link>
          <Link className="page_link url_scroll_up content_refresh" to="/DeathNotices" reset_elem=".death_notices_page_container .reset_form_button">Death Notices</Link>
          <Link className="page_link url_scroll_up content_refresh" to="/Memorials" reset_elem=".memorials_page_container .reset_form_button">Memorials</Link>
          <Link className="page_link url_scroll_up content_refresh" to="/InMemoryNotices" reset_elem=".familynotice_page_container .reset_form_button">In Memory Notices</Link>
          <Link className="page_link url_scroll_up pet_tribute_refresh" to="/PetTributes" reset_elem=".pet_tribute_page_container .reset_form_button">Pet Tributes</Link>
          <Link className="page_link url_scroll_up blogs_refresh" to="/Blogs">Blogs</Link>
        </div>
        <div className="services_and_contact_grid">
          <div>
            <div className="contact_label">Services</div>
            <div className="footer_links_container">
              <div><a className="page_link" href="/ComingSoon">Shop</a></div>
              <div><Link className="url_scroll_up blogs_refresh" to="/Blogs">Blogs </Link></div>
              <div><Link className="url_scroll_up charity_refresh" to="/Charities">Charity</Link></div>
              <div><Link className="url_scroll_up services_refresh" to="/Services">Service Directory</Link></div>
              {/* <div><Link to="#">Advertise with us</Link></div> */}
              <div><Link className="url_scroll_up" to="/PartnerInformation">Partner Information </Link></div>
            </div>
          </div>
          <div>
            <div>
              <div className="contact_label">Useful Links</div>
              <div className="footer_links_container">
                <div><Link className="url_scroll_up" to="/Help">Help</Link></div>
                <div><Link className="url_scroll_up" to="/FAQs">FAQs</Link></div>
                <div><Link className="url_scroll_up" to="/Features">Features </Link></div>
                <div><Link className="url_scroll_up" to="/Feedback">Feedback</Link></div>
                <div><Link className="url_scroll_up" to="/ContactUs">Contact Us</Link></div>

              </div>
            </div>
          </div>
          <div>
            <div className="contact_label">Information</div>
            <div className="footer_links_container">
              <div><Link className="url_scroll_up" to="/Disclaimer">Disclaimer </Link></div>
              <div><Link className="url_scroll_up" to="/PrivacyPolicy">Privacy Policy </Link></div>
              <div><Link className="url_scroll_up" to="/CookiesPolicy">Cookies Policy</Link></div>
              <div><Link className="url_scroll_up" to="/CodeOfConduct">Code of Conduct </Link></div>
              <div><Link className="url_scroll_up" to="/TermsAndConditions">Terms and Conditions</Link></div>
            </div>
          </div>
          <div className="contact_container">
            <div className="contact_label">Get in Touch</div>
            <div className="footer_social_links_container">
              <a href="https://www.facebook.com/people/Amrityum/100077085990115/" target={"_blank"}><i className="fab fa-facebook-f contact_icon"></i></a>
              <a href="https://www.linkedin.com/in/amrityum-lifelog-matters-8a9027236" target={"_blank"}><i className="fab fa-linkedin contact_icon"></i></a>
              <a href="https://www.instagram.com/amrityum" target={"_blank"}><i className="fab fa-instagram contact_icon"></i></a>
              <a href={"https://api.whatsapp.com/send?phone=7558900111"} target={"_blank"}><i className="fab fa-whatsapp contact_icon"></i></a>
              <a href="https://in.pinterest.com/contactamrityum" target={"_blank"}><i className="fab fa-pinterest contact_icon"></i></a>
              <a href="https://x.com/amrityum" target={"_blank"}>
                <img
                  src={x_icon}// Replace with the actual URL of the "X" logo SVG
                  alt="Twitter (X)"
                  style={{ width: '19px', paddingTop:"4px"}}
                  className="contact_icon"
                /></a>
              <a href={"https://www.youtube.com/channel/UCIPjOEE_yeWIl5O8qUuQnAw/featured"} target={"_blank"}>
                <i className="fab fa-youtube contact_icon" ></i>
              </a>
            </div>
            {/* <div className="contact_label" style={{marginTop:"5px"}}>Phone</div> */}
            <div className="contact_value"><a href="tel:+91 7558 900 111">+91 7558 900 111</a></div>
            {/* <div className="contact_label" style={{marginTop:"5px"}}>Email</div> */}
            <div className="contact_value"><a href="mailto:info@amrityum.com">info@amrityum.com</a></div>

          </div>
        </div>
        <div className="copyright_container">&copy; {new Date().getFullYear()} Amrityum. All rights reserved
          <br/>
          IT Partner : <a href="https://www.dieutek.com" target={"_blank"} style={{ color: "#5d5dc6" }}>Dieutek Developments</a>
        </div>
      </div>
    )
  }
}

export default Footer;