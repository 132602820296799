import React from "react";
import "./PartnerProfile.scss";
import $ from "jquery";
import MyContext from "../MyContext";
import { InputTheme4, InputTheme6 } from "../../res/styler/InputThemes/InputThemes";
import { MdFavorite, MdHomeRepairService, MdSpeed } from "react-icons/md";
import { ButtonTheme1 } from "../../res/styler/ButtonThemes/ButtonThemes";
import { FaMapMarkerAlt, FaPhoneAlt, FaGlobe } from "react-icons/fa";
import { SiGmail, SiWhatsapp } from "react-icons/si";
import { BsChevronDoubleDown, BsChevronDoubleUp, BsStarFill, BsStarHalf } from 'react-icons/bs';
import { ImArrowRight2 } from 'react-icons/im';
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineKeyboardBackspace, MdOutlineShare, MdOutlinePrint } from 'react-icons/md';
import { FaRegComment, FaUserCircle } from 'react-icons/fa';
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { GoReport } from 'react-icons/go';
import { ButtonTheme2 } from "../../res/styler/ButtonThemes/ButtonThemes";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import user_img from "../../res/images/user_img.jpg";
import partner_title from "../../res/images/partner_title.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import partner_img from "../../res/images/service_default.jpg";
import { FiActivity, FiMusic, FiTwitter } from "react-icons/fi";
import work from "../../res/images/home/work.png";
import AwesomeSlider from 'react-awesome-slider';
import AwsSliderStyles from 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import { VscChromeClose } from "react-icons/vsc";
import x_logo from "../../res/images/X_logo_black.png";

class PartnerProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      partner_details: {
        business_name: "",
        google_location: "",
        gallery_images: [],
        services: [],
        comments: []
      },
      name: "",
      last_name: "",
      email: "",
      phone_no: "",
      phone_number_code: "IN",
      message: "",
      manage_partner: "",
      zoomed_item: {},
      image_index: 0,
      comment: "",
      reject_reason: "",
      captcha_token: "",
      deleted_reason: "",
      partner_id: "",
    }

    this.delete_reason_container_ref = React.createRef();
    this.form_change = this.form_change.bind(this);
    this.enquiry_form_submit = this.enquiry_form_submit.bind(this);
    this.phone_no_change = this.phone_no_change.bind(this);
    this.phone_number_code_change = this.phone_number_code_change.bind(this);
    this.approve_partner = this.approve_partner.bind(this);
    this.delete_partner = this.delete_partner.bind(this);
    this.delete_form_submit = this.delete_form_submit.bind(this);
    this.reject_partner = this.reject_partner.bind(this);
    this.zoomImage = this.zoomImage.bind(this);
    this.minimizeImage = this.minimizeImage.bind(this);
    this.nextImage = this.nextImage.bind(this);
    this.previousImage = this.previousImage.bind(this);
    this.show_comment_form = this.show_comment_form.bind(this);
    this.comment_form_submit = this.comment_form_submit.bind(this);
    this.show_report_page_form = this.show_report_page_form.bind(this);
    this.report_page_form_submit = this.report_page_form_submit.bind(this);
    this.reject_partner = this.reject_partner.bind(this);
    this.reject_form_submit = this.reject_form_submit.bind(this);
    this.on_like_button_click = this.on_like_button_click.bind(this);
    this.bind_likes = this.bind_likes.bind(this);
    this.report_message = this.report_message.bind(this);
    this.partner_profile_page_ref = React.createRef();
    this.reject_reason_container_ref = React.createRef();
    this.captchaRef = React.createRef();
    this.enquiry_form_ref = React.createRef();
    this.name_ref = React.createRef();
    this.last_name_ref = React.createRef();
    this.phone_no_ref = React.createRef();
    this.email_ref = React.createRef();
    this.message_ref = React.createRef();
    this.month_names = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    this.user_likes = [];
    this.user_like_count = [];
  }

  componentDidMount() {
    $(".header_container").addClass("transparent_header");
    if (window.location.pathname == "/Preview/Partners/" + this.props.match.params.partner_id) {
      var obj_this = this;
      $.ajax({
        url: process.env.REACT_APP_API + "/api/viewPartnerDetails",
        data: this.context.bind_session({
          partner_id: this.props.match.params.partner_id,
          email: this.context.session.email
        }),
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          if (return_data.partner_details.status == "DELETED") {
            obj_this.props.history.push('/Services')
          }
          obj_this.user_likes = return_data.user_likes;
          obj_this.user_like_count = return_data.user_like_count;
          const comments = return_data.partner_details.comments;
          obj_this.bind_likes(comments, "comment");
          obj_this.setState({
            partner_details: return_data.partner_details,
            manage_partner: 1
          }, () => {
            obj_this.partner_profile_page_ref.current.style.visibility = "visible";
            $(".header_container").css("pointer-events", "none");
            $(".like_button,.single_charity, .report_button, .button_theme2_container,.all_charity_button, .new_comment_button, .donate_now_button, .custom_button, .shop_now_button, .save_message_button").css("pointer-events", "none");
            $(".share_print_comment_buttons_container, .footer_container").css("display", "none");
            window.scrollTo({
              top: obj_this.partner_profile_page_ref.current.offsetTop - 80,
              behavior: "smooth"
            });
          });
          obj_this.context.loading(0);
        } else {

        }
      }).fail(function () {
        $.alert("Network error");
      });
    } else {
      var obj_this = this;
      $.ajax({
        url: process.env.REACT_APP_API + "/api/getPartnerDetails",
        data: {
          partner_id: this.props.match.params.partner_id,
          email: this.context.session.email
        },
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.status == "success") {
          if (return_data.partner_details.status == "DELETED") {
            obj_this.props.history.push('/Services')
          }
          obj_this.user_likes = return_data.user_likes;
          obj_this.user_like_count = return_data.user_like_count;
          const comments = return_data.partner_details.comments;
          obj_this.bind_likes(comments, "comment");
          obj_this.setState({
            partner_details: return_data.partner_details,
          }, () => {
            $(".map_block").html(obj_this.state.partner_details.google_location);
            obj_this.partner_profile_page_ref.current.style.visibility = "visible";
            window.scrollTo({
              top: obj_this.partner_profile_page_ref.current.offsetTop - 80,
              behavior: "smooth"
            });
            obj_this.props.history.replace("/Partners/" + obj_this.state.partner_details.id + "/" + obj_this.state.partner_details.business_name.replace(/\s+/g, '-') + "/" + obj_this.state.partner_details.serial_no);
          });
        }
        obj_this.context.loading(0);
      }).fail(function () {
        $.alert("Network error");
      });
    }
  }

  componentWillUnmount() {
    $(".footer_container").css("display", "block");
    $(".header_container").css("pointer-events", "auto").removeClass("transparent_header");
  }


  // bind_likes(messages,message_type){
  //   //this function bind likes count and check whether the message is liked or not by logined user
  //   messages.map((message) => {
  //     message.liked = 0;
  //     message.like_count = 0;
  //     this.user_likes.map((item) => {
  //       if(item.content_type == message_type){
  //         if(item.content_id == message.id){
  //           if(this.context.session){
  //             message.liked = 1;
  //           }
  //         }
  //       }
  //     });
  //     this.user_like_count.map((item) => {
  //       if(item.content_type == message_type){
  //         if(item.content_id == message.id){
  //           message.like_count = item.like_count;
  //         }
  //       }
  //     });
  //   });
  // }

  // on_like_button_click(id,type,e){
  //   if(!this.context.session){
  //     alert("Sign in to continue");
  //     this.props.history.push({ pathname: "/Login", url : "/Partners/"+this.props.match.params.partner_id})
  //     return;
  //   }
  //   // console.log(this.context.session);
  //   // return;
  //   // console.log($(e.currentTarget).find(".like_button_label").html())
  //   if($(e.currentTarget).find(".like_button_label").html() == "Like"){
  //     //like
  //     var obj_this = this;
  //     this.context.loading(1);
  //     const liked_content = {
  //       notice_type: "partner" ,
  //       content_id : id,
  //       content_type : type
  //     }
  //     $.ajax({
  //       url: process.env.REACT_APP_API + "/api/likeContent",
  //       data: this.context.bind_session({liked_content : JSON.stringify(liked_content)}),
  //       type: "POST"
  //     }).done(function (return_data){
  //       // console.log(return_data);
  //       // return;
  //       if (return_data.status == "success") {
  //         obj_this.context.loading(0);
  //         if(type == "comment"){
  //           const comments = obj_this.state.partner_details.comments;
  //           comments.map((item) => {
  //             if(item.id == id){
  //               item.liked = 1;
  //               ++item.like_count;
  //             }
  //           });
  //           obj_this.setState({
  //             comments :comments
  //           });
  //         }
  //       }else if(return_data.status == "session failed"){
  //         obj_this.props.history.push({ pathname: "/Login", url : "/Partners/"+obj_this.props.match.params.partner_id})
  //       }else{
  //         alert("Network error!!");
  //       }
  //     }).fail(function (return_data) {
  //       alert("Network error!!");
  //     });
  //   }else{
  //     //dislike
  //     var obj_this = this;
  //     this.context.loading(1);
  //     const disliked_content = {
  //       notice_type: "partner" ,
  //       content_id : id,
  //       content_type : type
  //     }
  //     $.ajax({
  //       url: process.env.REACT_APP_API + "/api/disLikeContent",
  //       data: this.context.bind_session({disliked_content : JSON.stringify(disliked_content)}),
  //       type: "POST"
  //     }).done(function (return_data) {
  //       if (return_data.status == "success") {
  //         obj_this.context.loading(0);
  //          if(type == "comment"){
  //           const comments = obj_this.state.partner_details.comments;
  //           comments.map((item) => {
  //             if(item.id == id){
  //               item.liked = 0;
  //               --item.like_count
  //             }
  //           });
  //           obj_this.setState({
  //             comments :comments
  //           });
  //         }
  //       }else if(return_data.status == "session failed"){
  //         obj_this.props.history.push({ pathname: "/Login", url : "/Partners/"+obj_this.props.match.params.partner_id})
  //       }else {
  //         alert("Network error!!");
  //       }
  //     }).fail(function (return_data) {
  //       alert("Network error!!");
  //     });
  //   }
  // }

  // report_message(id) {
  //   if (!this.context.session) {
  //     this.context.confirm("Sign in to continue",() => {
  //       this.props.history.push({ pathname: "/Login", url: "/Partners/" + this.props.match.params.partner_id })
  //     });
  //     return;
  //   }else{
  //     this.context.confirm("Report this comment",() => {
  //       var obj_this = this;
  //       this.context.loading(1);
  //       const report_data = {
  //         partner_id: this.state.partner_details.id,
  //         content_id: id,
  //         content_type: "comment"
  //       }
  //       $.ajax({
  //         url: process.env.REACT_APP_API + "/api/reportPartnerContent",
  //         data: this.context.bind_session({ report_data: JSON.stringify(report_data) }),
  //         type: "POST"
  //       }).done(function (return_data) {
  //         if (return_data.status == "success") {
  //           obj_this.context.loading(0);
  //           $.alert("Your request will be reviewed.");
  //         } else {
  //           alert("Network error!!");
  //         }
  //       }).fail(function (return_data) {
  //         alert("Network error!!");
  //       });
  //     });
  //   }
  // }

  bind_likes(messages, message_type) {
    //this function bind likes count and check whether the message is liked or not by logined user
    messages.map((message) => {
      message.liked = 0;
      message.like_count = 0;
      this.user_likes.map((item) => {
        if (item.content_type == message_type) {
          if (item.content_id == message.id) {
            if (this.context.session) {
              message.liked = 1;
            }
          }
        }
      });
      this.user_like_count.map((item) => {
        if (item.content_type == message_type) {
          if (item.content_id == message.id) {
            message.like_count = item.like_count;
          }
        }
      });
    });
  }

  on_like_button_click(id, type, e) {
    if (!this.context.session) {
      this.context.confirm("Sign in to continue", () => {
        this.props.history.push({ pathname: "/Login", url: "/Partners/" + this.props.match.params.partner_id });
      });
      return;
    }
    // console.log(this.context.session);
    // return;
    // console.log($(e.currentTarget).find(".like_button_label").html())
    if ($(e.currentTarget).find(".like_button_label").html() == "Like") {
      //like
      var obj_this = this;
      this.context.loading(1);
      const liked_content = {
        notice_type: "partner",
        content_id: id,
        content_type: type
      }
      $.ajax({
        url: process.env.REACT_APP_API + "/api/likeContent",
        data: this.context.bind_session({ liked_content: JSON.stringify(liked_content) }),
        type: "POST"
      }).done(function (return_data) {
        // console.log(return_data);
        // return;
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          if (type == "comment") {
            const comments = obj_this.state.partner_details.comments;
            comments.map((item) => {
              if (item.id == id) {
                item.liked = 1;
                ++item.like_count;
              }
            });
            obj_this.setState({
              comments: comments
            });
          }
        } else if (return_data.status == "session failed") {
          obj_this.props.history.push({ pathname: "/Login", url: "/Partners/" + obj_this.props.match.params.partner_id })
        } else {
          alert("Network error!!");
        }
      }).fail(function (return_data) {
        alert("Network error!!");
      });
    } else {
      //dislike
      var obj_this = this;
      this.context.loading(1);
      const disliked_content = {
        notice_type: "partner",
        content_id: id,
        content_type: type
      }
      $.ajax({
        url: process.env.REACT_APP_API + "/api/disLikeContent",
        data: this.context.bind_session({ disliked_content: JSON.stringify(disliked_content) }),
        type: "POST"
      }).done(function (return_data) {
        if (return_data.status == "success") {
          obj_this.context.loading(0);
          if (type == "comment") {
            const comments = obj_this.state.partner_details.comments;
            comments.map((item) => {
              if (item.id == id) {
                item.liked = 0;
                --item.like_count
              }
            });
            obj_this.setState({
              comments: comments
            });
          }
        } else if (return_data.status == "session failed") {
          obj_this.props.history.push({ pathname: "/Login", url: "/Partners/" + obj_this.props.match.params.partner_id })
        } else {
          alert("Network error!!");
        }
      }).fail(function (return_data) {
        alert("Network error!!");
      });
    }
  }

  report_message(id) {
    if (!this.context.session) {
      this.context.confirm("Sign in to continue", () => {
        this.props.history.push({ pathname: "/Login", url: "/Partners/" + this.props.match.params.partner_id })
      });
      return;
    } else {
      this.context.confirm("Report this comment", () => {
        var obj_this = this;
        this.context.loading(1);
        const report_data = {
          partner_id: this.state.partner_details.id,
          content_id: id,
          content_type: "comment"
        }
        $.ajax({
          url: process.env.REACT_APP_API + "/api/reportPartnerContent",
          data: this.context.bind_session({ report_data: JSON.stringify(report_data) }),
          type: "POST"
        }).done(function (return_data) {
          if (return_data.status == "success") {
            obj_this.context.loading(0);
            $.alert("Your request will be reviewed.");
          } else {
            alert("Network error!!");
          }
        }).fail(function (return_data) {
          alert("Network error!!");
        });
      });
    }
  }

  form_change(e) {
    if (e.target.value.length > 200) {
      $.alert("Maximum characters exceeded.")
      return;
    }
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  phone_no_change(value) {
    this.setState({
      phone_no: value,
    });
  }

  phone_number_code_change(country) {
    const country_code = country ?? this.state.phone_number_code;
    this.setState({
      phone_number_code: country_code,
    });
  }

  enquiry_form_submit(e) {
    e.preventDefault();
    if (!this.state.name) {
      $.alert("First Name Required");
      window.scrollTo({
        top: $(this.name_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.last_name) {
      $.alert("Last Name Required");
      window.scrollTo({
        top: $(this.last_name_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.phone_no) {
      $.alert("Phone Number Required");
      window.scrollTo({
        top: $(this.phone_no_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var re = /\S+@\S+\.\S+/;
    if (!re.test(this.state.email)) {
      $.alert("Please enter an email address");
      window.scrollTo({
        top: $(this.email_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.message) {
      $.alert("Message Required");
      window.scrollTo({
        top: $(this.message_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    const token = this.captchaRef.current.getValue();
    this.captchaRef.current.reset();
    var obj_this = this;
    var enquiry_data = {
      name: this.state.name,
      last_name: this.state.last_name,
      phone_no: this.state.phone_no,
      email: this.state.email,
      message: this.state.message,
      partner_id: this.state.partner_details.id
    }
    enquiry_data.captcha_token = token;
    if (!enquiry_data.captcha_token) {
      $.alert("Please verify you are a human!!");
      return;
    }
    this.context.loading(1);
    console.log(enquiry_data);
    enquiry_data = JSON.stringify(enquiry_data);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/savePartnerEnquiry",
      data: {
        enquiry_data: enquiry_data,
      },
      type: "POST"
    }).done(function (return_data) {
      // console.log(return_data);
      obj_this.context.loading(0);
      if (return_data.status == "success") {
        $.alert("Thank you. Your enquiry has been submitted successfully");
        obj_this.setState({
          name: "",
          last_name: "",
          phone_no: "",
          phone_number_code: "IN",
          email: "",
          message: "",
          enquiry_for: "",
          captcha_token: "",
        });
      } else {
        $.alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (return_data) {
      $.alert("Network error!!");
    });
  }

  zoomImage(item, idx) {
    console.log(item);
    this.setState({
      zoomed_item: item,
      image_index: idx
    });
  }

  minimizeImage(item) {
    this.setState({
      zoomed_item: {}
    });
  }

  previousImage() {
    const item = this.state.partner_details.gallery_images[--this.state.image_index];
    if (item)
      this.setState({
        zoomed_item: item,
      });
  }

  nextImage(e) {
    const item = this.state.partner_details.gallery_images[++this.state.image_index];
    if (item)
      this.setState({
        zoomed_item: item,
      });
  }

  show_comment_form() {
    if (this.context.session) {
      $(".add_message_form_ghost").css("display", "block");
    } else {
      this.context.confirm("Sign in to leave a comment", () => {
        this.props.history.push({ pathname: "/Login", url: "/Partners/" + this.props.match.params.partner_id })
      });
      return;
    }
  }

  comment_form_submit(e) {
    e.preventDefault();
    // console.log(this.state.comment);
    var obj_this = this;
    this.context.loading(1);
    $(".add_message_form_ghost").css("display", "none");
    $.ajax({
      url: process.env.REACT_APP_API + "/api/savePartnerComments",
      data: this.context.bind_session({
        partner_id: this.props.match.params.partner_id,
        comment: this.state.comment
      }),
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      if (return_data.status == "success") {
        const partner_details = obj_this.state.partner_details;
        obj_this.user_likes = return_data.user_likes;
        obj_this.user_like_count = return_data.user_like_count;
        const comments = return_data.comments;
        obj_this.bind_likes(comments, "comment");
        partner_details.comments = comments;
        obj_this.setState({
          partner_details: partner_details,
          comment: ""
        });
        if (return_data.comment_status == "APPROVED") {
          $.alert("Your message is published");
        } else {
          $.alert("Your message is waiting for administrator approval, Please check back later.");
        }
      } else {
        $.alert("Something Went Wrong. Please Try Again.")
      }
      obj_this.context.loading(0);
    }).fail(function () {
      $.alert("Network error")
    });
  }

  show_report_page_form() {
    if (this.context.session) {
      $(".report_page_form_ghost").css("display", "block");
    } else {
      this.context.confirm("Sign in to report this page", () => {
        this.props.history.push({ pathname: "/Login", url: "/Partners/" + this.props.match.params.partner_id })
      });
    }
  }

  report_page_form_submit(e) {
    e.preventDefault();
    console.log(this.state.reason);
    // return;
    var obj_this = this;
    this.context.loading(1);
    $(".report_page_form_ghost").css("display", "none");
    const reported_data = {
      notice_id: this.state.partner_details.id,
      notice_type: "partner",
      reason: this.state.reason
    };
    $.ajax({
      url: process.env.REACT_APP_API + "/api/reportNotice",
      data: this.context.bind_session({
        reported_data: JSON.stringify(reported_data)
      }),
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      if (return_data.status == "success") {
        obj_this.setState({
          reason: ""
        });
        $.alert("Your message is waiting for administrator approval. Please check back later.");
      } else {
        $.alert("Something Went Wrong, Please Try Again");
      }
      obj_this.context.loading(0);
    }).fail(function () {
      alert("Network error")
    });
  }

  approve_partner() {
    if (!window.confirm("Approve this partner")) {
      return;
    }
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/approvePartner",
      data: this.context.bind_session({
        partner_id: this.props.match.params.partner_id
      }),
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      alert("Partner approved");
      obj_this.props.history.push({
        pathname: obj_this.props.location.url ?? "/Dashboard",
        state: obj_this.props.location.previousState ?? ""
      });
      obj_this.context.loading(0);
    }).fail(function () {
      $.alert("Network error");
    });
  }

  delete_partner() {
    if (!window.confirm("Are your sure")) {
      return;
    }
    this.delete_reason_container_ref.current.style.display = "block";
  }

  delete_form_submit(e) {
    e.preventDefault();
    var obj_this = this;
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deletePartnerServices",
      data: this.context.bind_session({
        partner_id: this.props.match.params.partner_id,
        deleted_reason: this.state.deleted_reason,
      }),
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      alert("Partner deleted");
      obj_this.props.history.replace({
        pathname: obj_this.props.location.url ?? "/Dashboard",
        state: obj_this.props.location.previousState ?? ""
      });
      obj_this.context.loading(0);
    }).fail(function () {
      $.alert("Network error");
    });
  }

  reject_partner() {
    if (!window.confirm("Reject this service")) {
      return;
    }
    this.reject_reason_container_ref.current.style.display = "block";

  }

  reject_form_submit(e) {
    e.preventDefault();
    var obj_this = this
    this.context.loading(1);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/rejectPartnerServices",
      data: this.context.bind_session({
        partner_id: this.props.match.params.partner_id,
        reject_reason: this.state.reject_reason
      }),
      type: "POST"
    }).done(function (return_data) {
      if (return_data.status == "success") {
        obj_this.context.loading(0);
        alert("Partner rejected.");
        obj_this.props.history.replace({
          pathname: obj_this.props.location.url ?? "/Dashboard",
          state: obj_this.props.location.previousState ?? ""
        });
      } else {
        alert("Network error!!");
      }
    }).fail(function (return_data) {
      alert("Network error!!");
    });
  }



  // reject_partner(){
  //   if (!window.confirm("Reject this service")) {
  //     return;
  //   }
  //   var obj_this = this;
  //   this.context.loading(1);
  //   $.ajax({
  //     url : process.env.REACT_APP_API + "/api/rejectPartnerServices",
  //     data : this.context.bind_session({
  //       partner_id : this.props.match.params.partner_id
  //     }),
  //     type : "POST"
  //   }).done(function(return_data){
  //     console.log(return_data);
  //     alert("Partner rejected");
  //     obj_this.props.history.push(obj_this.props.location.url ?? "/Dashboard");
  //     obj_this.context.loading(0);
  //   }).fail(function () {
  //       $.alert("Network error");
  //   });
  // }


  render() {
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    return (
      <div className="partner_profile_page_container" ref={this.partner_profile_page_ref}>
        <div className="title_block no_select">
          <div className="title_buttons_container">
            <div className="page_title">{this.state.partner_details.business_name}</div>
            <hr className="solid"></hr>
            <div className="logo_container">
              <div className="logo_image" style={{ backgroundImage: "url('" + process.env.REACT_APP_CDN_URL + "/partners/logo/" + this.state.partner_details.image_src + "')" }}></div>
            </div>
            <div className="title_content" dangerouslySetInnerHTML={{ __html: this.state.partner_details.business_description }}>
            </div>

            <div className="button_container">
              <div className="notice_button_theme1"
                onClick={() => window.scrollTo({
                  top: this.enquiry_form_ref.current.offsetTop - 40,
                  behavior: "smooth"
                })}>
                <div className="button_ghost"></div>
                <div className="button_label">contact us</div>
              </div>
            </div>
          </div>
        </div>

        <div className="about_block">
          <div className="about_grid">
            {this.state.partner_details.cover_image_src ?
              <div className="cover_image_container" style={{ backgroundImage: "url('" + process.env.REACT_APP_CDN_URL + "/partners/cover_images/" + this.state.partner_details.cover_image_src + "')" }}></div>
              :
              <div className="cover_image_container" style={{ backgroundImage: "url('" + partner_img + "')" }}>
              </div>
            }
            {/* <div className="content_section"> */}
            <div className="heading">{this.state.partner_details.business_name}</div>
            <div className="about_content" dangerouslySetInnerHTML={{ __html: this.state.partner_details.services_description }}></div>
            <div className="about_button"><ButtonTheme2
              label="Send Us An Enquiry"
              className="create-button"
              onClick={() => window.scrollTo({
                top: this.enquiry_form_ref.current.offsetTop - 40,
                behavior: "smooth"
              })}
            />
              {/* </div> */}
            </div>
          </div>
        </div>

        <div className="services_block">
          <div className="global_label">
            Services
          </div>
          <MdFavorite className="love_icon" />
          <div className="global_heading">Our Departments</div>
          <div className="services_grid">
            {this.state.partner_details.services && this.state.partner_details.services.map((item, idx) => (
              <div className="single_service_container" key={idx}>
                <div className="icon_container" style={{ backgroundImage: "url('" + work + "')" }}></div>
                <div className="service_name">{item.id == 24 ? this.state.partner_details.other_service : item.service}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="gallery_block">
          <div className="global_label">Gallery</div>
          <div className="global_heading">Our sample images</div>
          <div className="gallery_images_container">
            {this.state.partner_details.gallery_images && this.state.partner_details.gallery_images.map((item, idx) => (
              <div className="single_image_container" key={idx}>
                <div className="single_image"
                  onClick={() => this.zoomImage(item, idx)}
                  style={{ backgroundImage: "url('" + process.env.REACT_APP_CDN_URL + "/" + this.state.partner_details.user_id + "/gallery_images/" + item.gallery_image_src + "')" }}>
                </div>
                {/* <div className="img_caption">{item.gallery_image_caption}</div> */}
              </div>
            ))}
          </div>
          <div className="gallery_images_slider">
            <AutoplaySlider cssModule={AwsSliderStyles}
              play={false}
              cancelOnInteraction={false}
              interval={1000}
              onTransitionStart={this.onTransitionStart}
              onTransitionEnd={this.onTransitionEnd}
              transitionDelay={100}
              showTime={false}
              buttons={this.state.partner_details.gallery_images.length > 1 ? true : false}
              mobileTouch={this.state.partner_details.gallery_images.length > 1 ? true : false}
              bullets={false}
              organicArrows={true}
            // mobileTouch={true}
            >
              {this.state.partner_details.gallery_images && this.state.partner_details.gallery_images.map((item, idx) => (
                <div className="slider_image" key={idx} data-src={process.env.REACT_APP_CDN_URL + "/" + this.state.partner_details.user_id + "/gallery_images/" + item.gallery_image_src}></div>
              ))}
            </AutoplaySlider>
          </div>
        </div>

        <div className="comments_block">
          <div className="global_label">Testimonials</div>
          <div className="global_heading">What clients say</div>
          <div style={{ textAlign: "center" }}>
            <button className="new_comment_button"
              onClick={this.show_comment_form}
            >
              <div className="button_ghost"></div>
              <i className="fas fa-pencil-alt"></i>
              <span className="button_label"> Leave a comment </span>
            </button>
          </div>
          {/* <Carousel
            additionalTransfrom={0}
            arrows
            centerMode={false}
            className="comments_container"
            containerClass="slider_container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 2400,
                  min: 1080
                },
                items: 2,
                partialVisibilityGutter: 100
              },
              mobile: {
                breakpoint: {
                  max: 780,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 780
                },
                items: 2,
                partialVisibilityGutter: 30
              }
            }}
            showDots={true}
            sliderClass=""
            slidesToSlide={1}
            swipeable>
            {this.state.partner_details.comments && this.state.partner_details.comments.map((item, idx) => (
              <div className="single_comment" key={idx}>
                <div className="comment_header">
                  {item.user_image_src ?
                    (!item.login_type) ?
                      <div className="user_image" style={{ backgroundImage: "url('" + process.env.REACT_APP_CDN_URL + "/user_profile_images/" + item.user_image_src + "')" }}></div>
                      :
                      <img className="user_image" src={(item.user_image_src)} referrerPolicy="no-referrer" />
                    :
                    <div className="user_image" style={{ backgroundImage: "url('" + user_img + "')" }}></div>
                  }
                  <div className="comment_details">
                    <div className="commented_user">{item.fullname}</div>
                    <div className="user_location">{item.user_location}</div>
                    <div className="commented_date">{new Date(item.published_date).toLocaleString("en-IN", { weekday: "long" })}, {new Date(item.published_date).getDate()} {this.month_names[new Date(item.published_date).getMonth()]} {new Date(item.published_date).getFullYear()}</div>
                  </div>
                </div>
                <div className="comment_body">{item.comment}</div>
                <div className="like_report_grid">
                    <div className="likes_container">
                      <div className="like_button"
                        onClick={(e) => this.on_like_button_click(item.id,"comment",e)}
                        style={{backgroundColor:item.liked?"":"#ff365f",color:item.liked?"#ff365f":""}}
                      >
                        <div className="like_button_label">{item.liked?"Liked":"Like"}</div>
                        <AiFillHeart color="#ff365f" className="like_icon" style={{color:item.liked?'#ff365f':"white"}} />
                      </div>
                      <span className="likes_count" style={{display:item.like_count>0?"block":"none"}}> {item.like_count} {item.like_count>1?"Likes":"Like"}</span>
                    </div>
                    <div className="report_button" onClick={() => this.report_message(item.id,item.tribute_type)}>
                    <GoReport />
                    <div className="report_label">REPORT </div>
                    </div>
                </div>
              </div>
            ))}
          </Carousel> */}

          <Carousel
            additionalTransfrom={0}
            arrows
            centerMode={false}
            className="msg_container"
            containerClass="slider_container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 2400,
                  min: 1080
                },
                items: 2,
                partialVisibilityGutter: 100
              },
              mobile: {
                breakpoint: {
                  max: 780,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 780
                },
                items: 2,
                partialVisibilityGutter: 30
              }
            }}
            showDots={true}
            sliderClass=""
            slidesToSlide={1}
            swipeable>
            {this.state.partner_details.comments && this.state.partner_details.comments.map((item, idx) => (
              <div className="single_msg" key={idx}>
                {/* <div className="rating">
                  <BsStarFill className="rating_star" />
                  <BsStarFill className="rating_star" />
                  <BsStarFill className="rating_star" />
                  <BsStarFill className="rating_star" />
                  <BsStarHalf className="rating_star" /> */}
                {/* </div> */}
                <div className="comment_details">
                  <div className="commented_user">{item.fullname}</div>
                  {/* <div className="user_location">{item.user_location}</div> */}
                  <div className="commented_date">{new Date(item.published_date).toLocaleString("en-IN", { weekday: "long" })}, {new Date(item.published_date).getDate()} {this.month_names[new Date(item.published_date).getMonth()]} {new Date(item.published_date).getFullYear()}</div>
                </div>
                <hr style={{ width: "50%", marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}></hr>
                <div className="comment_body">{item.comment}</div>
                <FaUserCircle
                  size={80}
                  color="#08475e"
                  className="comments_user_icon"
                />

                {/* </div> */}
                <div className="like_report_grid">
                  <div className="likes_container">
                    <div className="like_button"
                      onClick={(e) => this.on_like_button_click(item.id, "comment", e)}
                      style={{ backgroundColor: item.liked ? "" : "#ff365f", color: item.liked ? "#ff365f" : "" }}
                    >
                      <div className="like_button_label">{item.liked ? "Liked" : "Like"}</div>
                      <AiFillHeart color="#ff365f" className="like_icon" style={{ color: item.liked ? '#ff365f' : "white" }} />
                    </div>
                    <span className="likes_count" style={{ display: item.like_count > 0 ? "block" : "none" }}> {item.like_count} {item.like_count > 1 ? "Likes" : "Like"}</span>
                  </div>
                  <div className="report_button" onClick={() => this.report_message(item.id, item.tribute_type)}>
                    <GoReport />
                    <div className="report_label">REPORT </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>

        <div className="buttons_container">
          <div className="share_print_comment_buttons_container">
            <div className="back_button custom_button"
              onClick={() => this.props.history.goBack()}
            >
              <div className="icon_container">
                <MdOutlineKeyboardBackspace className="icon" />
              </div>
              <div className="button_label">Back</div>
              {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
            </div>
            <div className="report_page_button custom_button"
              onClick={this.show_report_page_form}
            >
              <div className="icon_container">
                <GoReport className="icon" />
              </div>
              <div className="button_label">Report this page</div>
              {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
            </div>
            {/* <div className="comment_button custom_button"
            onClick={this.show_comment_form}
          >
            <div className="icon_container">
              <FaRegComment className="icon"/>
            </div>
            <div className="button_label">Comment</div>
            <ImArrowRight2 size={18}  className="arrow_icon"/>
          </div> */}
            <div className="share_button custom_button">
              <div className="icon_container">
                <MdOutlineShare className="icon" />
              </div>
              <div className="button_label">Share</div>
              {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
              <div className="share_buttons_container">
                <a href={"http://www.facebook.com/share.php?u=" + encodeURIComponent(process.env.REACT_APP_SHARE_URL + "/Partners/" + this.state.partner_details.id + "/" + this.state.partner_details.business_name.replace(/\s+/g, '-') + "/" + this.state.partner_details.serial_no)} target={"_blank"}>
                  <i className="fab fa-facebook-f share_icon" style={{ color: "#0f92f3" }}></i>
                  <span>Facebook</span>
                </a>
                <a href={"https://api.whatsapp.com/send?text=" + this.state.partner_details.business_name + " " + encodeURIComponent(process.env.REACT_APP_SHARE_URL + "/Partners/" + this.state.partner_details.id + "/" + this.state.partner_details.business_name.replace(/\s+/g, '-') + "/" + this.state.partner_details.serial_no)} data-action="share/whatsapp/share" target={"_blank"}>
                  <i className="fab fa-whatsapp share_icon" style={{ color: "#40bf50" }}></i>
                  <span>WhatsApp</span>
                </a>
                {/* <i className="fab fa-instagram share_icon"></i> */}
                <a href={"mailto:?subject=" + this.state.partner_details.business_name + "&body=" + process.env.REACT_APP_SHARE_URL + "/Partners/" + this.state.partner_details.id + "/" + this.state.partner_details.business_name.replace(/\s+/g, '-') + "/" + this.state.partner_details.serial_no} target={"_blank"}>
                  <i className="far fa-envelope-open share_icon" style={{ color: "#d10a0a" }}></i>
                  <span>Mail</span>
                </a>
                <a href={"https://x.com/intent/tweet?url=" + encodeURIComponent(process.env.REACT_APP_SHARE_URL + "/Partners/" + this.state.partner_details.id + "/" + this.state.partner_details.business_name.replace(/\s+/g, '-') + "/" + this.state.partner_details.serial_no)} target={"_blank"}>
                  <img src={x_logo} className="share_icon" style={{ paddingLeft: "5px", height: "20px" }} />
                  <span>X</span>
                </a>
                <a onClick={() => {
                  var copyURL = process.env.REACT_APP_SHARE_URL + "/Partners/" + this.state.partner_details.id + "/" + this.state.partner_details.business_name.replace(/\s+/g, '-') + "/" + this.state.partner_details.serial_no;
                  navigator.clipboard.writeText(copyURL);
                  $.alert("URL copied to clipboard");
                  setTimeout(() => {
                    $(".custom_alert_container .custom_alert_box .custon_alert_ok_button").trigger("click");
                  }, 2000);
                }}>
                  <i className="fa fa-clone share_icon" style={{ color: "grey" }}></i>
                  <span>Copy URL</span>
                </a>
              </div>
            </div>

            <div className="print_button custom_button"
              onClick={() => { window.print(); }}
            >
              <div className="icon_container">
                <MdOutlinePrint className="icon" />
              </div>
              <div className="button_label">Print</div>
              {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
            </div>
          </div>
        </div>

        <div className="enquiry_block" ref={this.enquiry_form_ref}>
          <div className="enquiry_block_heading">Have Some Question?</div>
          <div className="sub_heading">We appreciate your interest in the services we offer. Please fill out the form below or e-mail us at <br /><a className="enquiry" href={"mailto:" + this.state.partner_details.business_email}>{this.state.partner_details.business_email}</a> and we will get back to you promptly regarding your request</div>
          <div className="enquiry_grid">
            <div className="contact_box">
              <form onSubmit={this.enquiry_form_submit}>
                <div className="form_heading">Contact Us</div>
                <div className="divider"></div>
                <div className="form_container">
                  <InputTheme6
                    className="enquiry_form"
                    label="First Name"
                    attrs={{
                      class: "custom_class",
                      name: "name",
                      type: "text",
                      value: this.state.name,
                      onChange: this.form_change
                    }}
                    ref={this.name_ref}
                  />
                  <InputTheme6
                    className="enquiry_form"
                    label="Last Name"
                    attrs={{
                      class: "custom_class",
                      name: "last_name",
                      type: "text",
                      value: this.state.last_name,
                      onChange: this.form_change
                    }}
                    ref={this.last_name_ref}
                  />
                  <div className="mobile_no_input_container">
                    <PhoneInput
                      // style={{
                      //   borderBottom: this.state.phone_no
                      //     ? "2px solid #4f0662"
                      //     : "",
                      //   color: this.state.phone_no ? "#111" : "",
                      // }}
                      placeholder="Phone number"
                      international
                      value={this.state.phone_no}
                      onChange={this.phone_no_change}
                      defaultCountry={this.state.phone_number_code}
                      onCountryChange={(country) =>
                        this.phone_number_code_change(country)
                      }
                      ref={this.phone_no_ref}
                    />
                  </div>
                  <InputTheme6
                    className="enquiry_form"
                    label="Email"
                    attrs={{
                      name: "email",
                      type: "email",
                      value: this.state.email,
                      onChange: this.form_change,
                      autoComplete: "off"
                    }}
                    input_ref={this.email_ref}
                  />
                </div>
                <div className="other_details">
                  <textarea
                    name="message"
                    value={this.state.message}
                    placeholder="Message (max 150 words)"
                    onChange={this.form_change}
                    rows={3}
                    input_ref={this.message_ref}
                  />
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={this.captchaRef}
                  />
                  <button className="submit_button" type="submit">Send Enquiry</button>
                </div>
              </form>
            </div>
            <div className="enquiry_box">
              <div className="enquiry_heading">Request a Call Back</div>
              <div className="icon_details_grid">
                <a className="icon_container" href={this.state.partner_details.google_location} target={"_blank"}>
                  <FaMapMarkerAlt className="icon" />
                </a>
                <a className="details_container" href={this.state.partner_details.google_location} target={"_blank"}>
                  <div className="details">{this.state.partner_details.business_address}</div>
                  <div className="details">{this.state.partner_details.business_hours}</div>
                </a>
                <a className="icon_container" href={"tel:" + this.state.partner_details.business_phone_no}>
                  <FaPhoneAlt className="icon" />
                </a>
                <div className="details_container">
                  <div className="details"><a href={"tel:" + this.state.partner_details.business_phone_no}>{this.state.partner_details.business_phone_no}</a></div>
                </div>
                <a className="icon_container" href={"https://api.whatsapp.com/send?phone=" + this.state.partner_details.business_whatsapp_no} target={"_blank"}>
                  <SiWhatsapp className="icon" />
                </a>
                <div className="details_container">
                  <div className="details"><a href={"https://api.whatsapp.com/send?phone=" + this.state.partner_details.business_whatsapp_no} target={"_blank"}>{this.state.partner_details.business_whatsapp_no}</a></div>
                </div>
                <a className="icon_container" href={"mailto:" + this.state.partner_details.business_email}>
                  <SiGmail className="icon" />
                </a>
                <div className="details_container">
                  <div className="details"><a href={"mailto:" + this.state.partner_details.business_email}>{this.state.partner_details.business_email}</a></div>
                </div>
                <a className="icon_container" style={{ display: this.state.partner_details.website ? "block" : "none" }} href={this.state.partner_details.website} target={"_blank"}>
                  <FaGlobe className="icon" />
                </a>
                <div className="details_container" style={{ display: this.state.partner_details.website ? "block" : "none" }}>
                  <div className="details"><a href={this.state.partner_details.website} target={"_blank"}>{this.state.partner_details.website}</a></div>
                </div>
              </div>
              <div className="top_divider"></div>
              <div className="social_links_container">
                <a style={{ display: this.state.partner_details.facebook ? "inline" : "none" }} href={this.state.partner_details.facebook} target={"_blank"}><i className="fab fa-facebook-f icon_container"></i></a>
                <a style={{ display: this.state.partner_details.instagram ? "inline" : "none" }} href={this.state.partner_details.instagram} target={"_blank"}><i className="fab fa-instagram icon_container"></i></a>
                <a style={{ display: this.state.partner_details.twitter ? "inline" : "none" }} href={this.state.partner_details.twitter} target={"_blank"}>
                  <img src={x_logo} className="icon_container" style={{ paddingLeft: "5px", height: "20px" }} />
                </a>
                <a style={{ display: this.state.partner_details.linkedin ? "inline" : "none" }} href={this.state.partner_details.linkedin} target={"_blank"}><i className="fab fa-linkedin icon_container"></i></a>
              </div>
            </div>
          </div>
        </div>

        {this.state.partner_details.google_location && this.state.partner_details.google_location.includes("<iframe") ?
          <div className="map_block">

          </div> : ""}
        {/* <div className="buttons_container" style={{display:this.state.manage_partner?"block":"none"}}>
          <ButtonTheme1
            label="Approve"
            className="approve_button"
            onClick={this.approve_partner}
            style={{display:this.state.partner_details.status == "APPROVED"?"none":"inline-block"}}
          ></ButtonTheme1>
          <ButtonTheme1
            label="Delete"
            className="delete_button"
            onClick={this.delete_partner}
            style={{display:this.state.partner_details.status == "DELETED"?"none":"inline-block"}}
          ></ButtonTheme1>
        </div> */}
        <div className="approve_reject_buttons_container" style={{ display: this.state.manage_partner ? "block" : "none", textAlign: "center" }}>
          <ButtonTheme1
            label="<< Back"
            className="previous_page_button"
            onClick={() => this.props.history.goBack()}
          ></ButtonTheme1>
          <ButtonTheme1
            label="Delete"
            className="delete_button"
            onClick={this.delete_partner}
            style={{ display: this.props.history.location.action == "delete" && this.state.partner_details.status == "APPROVED" ? "inline-block" : "none" }}
          ></ButtonTheme1>
          {this.state.partner_details.status == "PENDING" ?
            <>
              <ButtonTheme1
                label="Reject"
                className="reject_button"
                onClick={this.reject_partner}
              ></ButtonTheme1>
              <ButtonTheme1
                label="Approve"
                className="approve_button"
                onClick={this.approve_partner}
              ></ButtonTheme1>
            </> : ""}
        </div>

        {/* <div className="zoomed_image_ghost" onClick={() => this.setState({ zoomed_item: "" })} style={{ display: this.state.zoomed_item.gallery_image_src ? "block" : "none" }}>
          <div className="zoomed_image_container" onClick={(e) => e.stopPropagation()}>
            <div className="image_caption_container">
              <img src={process.env.REACT_APP_CDN_URL + "/" + this.state.partner_details.user_id + "/gallery_images/" + this.state.zoomed_item.gallery_image_src} />
              <div style={{ display: this.state.zoomed_item.gallery_image_caption ? "block" : "none" }} className="image_caption">{this.state.zoomed_item.gallery_image_caption}</div>
              <div className="close_zoomed_image_button" onClick={this.minimizeImage}>
                <i className="fas fa-times"></i>
              </div>
            </div>
            <MdOutlineArrowBackIos
              className="image_previous_button"
              size={50}
              color="#333"
              onClick={this.previousImage}
              style={{ display: this.state.image_index == 0 ? "none" : "block" }}
            />
            <MdOutlineArrowForwardIos
              className="image_next_button"
              size={50}
              color="#333"
              onClick={this.nextImage}
              style={{ display: this.state.partner_details.gallery_images.length == this.state.image_index + 1 ? "none" : "block" }}
            />
          </div>
        </div> */}

        <div className="zoomed_image_ghost" onClick={() => this.setState({ zoomed_item: "" })} style={{ display: this.state.zoomed_item.gallery_image_src ? "block" : "none" }}>
          <div className="zoomed_image_container" onClick={(e) => e.stopPropagation()}>
            <div className="image_caption_container">
              <img src={process.env.REACT_APP_CDN_URL + "/" + this.state.partner_details.user_id + "/gallery_images/" + this.state.zoomed_item.gallery_image_src} />
              <div style={{ display: this.state.zoomed_item.gallery_image_caption ? "block" : "none" }} className="image_caption">{this.state.zoomed_item.gallery_image_caption}</div>
              <div className="close_zoomed_image_button" onClick={this.minimizeImage}>
                <VscChromeClose />
              </div>
            </div>
            <div className="arrow_container">
              <MdOutlineArrowBackIos
                className="image_previous_button"
                size={45}
                color="#333"
                onClick={this.previousImage}
                style={{ pointerEvents: this.state.image_index == 0 ? "none" : "auto", opacity: this.state.image_index == 0 ? 0.2 : 1 }}
              />
              <MdOutlineArrowForwardIos
                className="image_next_button"
                size={45}
                color="#333"
                onClick={this.nextImage}
                style={{ pointerEvents: this.state.partner_details.gallery_images.length == this.state.image_index + 1 ? "none" : "auto", opacity: this.state.partner_details.gallery_images.length == this.state.image_index + 1 ? 0.2 : 1 }}
              />
            </div>
          </div>

        </div>

        <div className="add_message_form_ghost"
        // onClick={() => $(".add_message_form_ghost").css("display", "none")}
        >
          <form className="add_message_form"
            onClick={(e) => e.stopPropagation()}
            onSubmit={this.comment_form_submit}
          >
            <div className="add_message_title">Add a Comment</div>
            <div className="leave_message_label">Write about your experience with us</div>
            <textarea
              name="comment"
              value={this.state.comment}
              rows={5}
              onChange={this.form_change}
              // maxLength={200}
              required
            />
            <div className="maximum_character_label">Max 200 characters</div>
            <ButtonTheme2 label="Send" type="submit" className="save_message_button" />
            <div className="close_message_form"
              onClick={() => {
                $(".add_message_form_ghost").css("display", "none");
                this.setState({
                  comment: ""
                })
              }}
            >
              <div>x</div>
            </div>
          </form>
        </div>

        <div className="report_page_form_ghost"
          onClick={() => {
            $(".report_page_form_ghost").css("display", "none");
            this.setState({
              reason: ""
            })
          }}
        >
          <form className="report_page_form"
            onClick={(e) => e.stopPropagation()}
            onSubmit={this.report_page_form_submit}
          >
            <div className="report_page_title">Report this page</div>
            <div className="leave_message_label">Reasons for reporting this service</div>
            <textarea
              name="reason"
              value={this.state.reason}
              rows={5}
              onChange={this.form_change}
              maxLength={200}
              required
            />
            <div className="maximum_character_label">Max 200 characters</div>
            <ButtonTheme2 label="Send" type="submit" className="save_message_button" />
            <div className="close_message_form"
              onClick={() => {
                $(".report_page_form_ghost").css("display", "none");
                this.setState({
                  reason: ""
                })
              }}
            >
              <div>x</div>
            </div>
          </form>
        </div>

        <div className="reject_reason_container" ref={this.reject_reason_container_ref}>
          <form className="reject_reason_form" onSubmit={this.reject_form_submit} method="post">
            <div>Reject Reason</div>
            <textarea name="reject_reason"
              rows={4}
              value={this.state.reject_reason}
              onChange={this.form_change}
              required
            />
            <button className="reject_form_submit_button">Submit</button>
            <i className="fa fa-times reject_form_close_button" onClick={() => { this.reject_reason_container_ref.current.style.display = "none"; this.setState({ reject_reason: "" }) }}></i>
          </form>

        </div>

        <div className="delete_reason_container" ref={this.delete_reason_container_ref}>
          <form className="delete_reason_form" onSubmit={this.delete_form_submit} method="post">
            <div className="delete_reason_title">Delete reason</div>
            <div className="delete_reason_form_body">
              <textarea name="deleted_reason"
                rows={4}
                value={this.state.deleted_reason}
                onChange={this.form_change}
                required
                placeholder="Type here.."
              />
              <button className="delete_form_submit_button">Submit</button>
            </div>
            <div className="delete_form_close_button" onClick={() => {
              this.delete_reason_container_ref.current.style.display = "none";
              this.setState({
                deleted_reason: "",
                selected_reported_reason: "",
                memorial_id: ""
              })
            }}>x</div>
          </form>
        </div>
      </div>
    )
  }
}

export default PartnerProfile;
PartnerProfile.contextType = MyContext;